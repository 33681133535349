import scoring from "static/data/scoring.json";
import movies from "static/data/movies.json";
import { preloadImage } from "utils/index";

const assetPreloader = async (updateProgress, info) => {
  const assetsToLoad = [];
  const failed = [];
  let total = null;
  let loaded = 0;
  const { imgPaths, version } = info;

  scoring.forEach((char) => {
    assetsToLoad.push(`${imgPaths.baseCharacterImgUrl}${char.Image}`);
  });

  movies
    .filter((m) => m[version] === 1)
    .forEach((m) => {
      const assetKey = version === "ES" ? "AssetAR" : "AssetBR";
      assetsToLoad.push(`${imgPaths.baseRecommendationImgUrl}${m[assetKey]}`);
    });
  total = assetsToLoad.length;

  for (const asset of assetsToLoad) {
    await preloadImage(asset).catch((e) => {
      console.log(e, asset);
      failed.push(asset);
    });
    loaded += 1;
    updateProgress(`Loaded: ${loaded} of ${total}`);
  }
  return failed;
};

export default assetPreloader;
