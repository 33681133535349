async function loadFile(path) {
  let error = false;
  let response;

  try {
    response = await fetch(path);
  } catch (e) {
    console.log(e);
    error = true;
  }
  const data = await response.json().catch(() => {
    console.log(path, " content is not valid JSON.");
    error = true;
  });
  const result = error === false ? data : null;
  return result;
}

function preloadImage(src) {
  return new Promise((resolve, reject) => {
    if (!src) {
      console.error("Invalid image source");
      return;
    }
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = (e) => reject(e);
    image.src = src;
  });
}

export function delay(ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export const generateShareLink = ({
  baseUrl,
  heart,
  energy,
  brain,
  redirect,
}) => {
  const base = baseUrl ?? `https://${document.domain}`;
  const data = { Heart: heart, Energy: energy, Brain: brain };
  const redirectKey = redirect ? "&redirect" : "";
  const url = `${base}?${JSON.stringify(data)}${redirectKey}`;
  return encodeURI(url);
};

function debounce(fn, interval, { leading } = {}) {
  let timeout;
  let leadExecuted = false;
  const timer = typeof interval === "number" ? interval : 200;
  const lead = typeof leading === "boolean" ? leading : false;
  return (...args) => {
    const context = this;
    const postponed = () => {
      timeout = null;
      if (lead) {
        leadExecuted = false;
      } else {
        fn.apply(context, args);
      }
    };
    clearTimeout(timeout);
    timeout = setTimeout(postponed, timer);
    if (lead && !leadExecuted) {
      leadExecuted = true;
      fn.apply(context, args);
    }
  };
}

function supportsLocalStorage() {
  try {
    localStorage.setItem("_test_", "_test_");
    localStorage.removeItem("_test_");
    return true;
  } catch (e) {
    return false;
  }
}

export const randomPickUnique = (arr, nPicks = 1) => {
  const ar = [...arr];
  const picks = [];
  let remPicks = nPicks;

  while (remPicks) {
    const pickIdx = Math.floor(Math.random() * ar.length);
    picks.push(ar[pickIdx]);
    ar.splice(pickIdx, 1);
    remPicks -= 1;
  }
  return picks;
};

const disableScroll = () => {
  document.body.style.top = `-${window.scrollY}px`;
  document.body.style.position = "fixed";
  // TODO: Browser / device detection.
  // Safari Mobile works better this way:
  // document.body.style.position = "absolute";
  // document.body.style.overflow = "hidden";
};

const enableScroll = () => {
  const scrollY = document.body.style.top;
  document.body.style.position = "";
  // TODO: Browser / device detection.
  document.body.style.top = "";
  window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
  // Safari Mobile works better this way:
  // document.body.style.overflow = "";
};

const saveToLocalStorage = (key, value) => {
  const timestamp = new Date().getTime();
  try {
    const object = JSON.stringify({ value, timestamp });
    localStorage.setItem(key, object);
  } catch {
    //
  }
};

const loadFromLocalStorage = (key) => {
  try {
    const value = localStorage.getItem(key);
    return JSON.parse(value);
  } catch (err) {
    return null;
  }
};

const isDateLessThan = (time, date) => new Date().getTime() - date < time;

const isMobile = () => {
  return /Mobi/.test(navigator.userAgent);
};

export {
  loadFile,
  debounce,
  enableScroll,
  disableScroll,
  supportsLocalStorage,
  preloadImage,
  loadFromLocalStorage,
  saveToLocalStorage,
  isDateLessThan,
  isMobile,
};
