import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import QRCode from "qrcode.react";
import {
  recommendations as selectRecommendations,
  selectImgPaths,
  selectRoutePaths,
  selectCopy,
  selectUsername,
  selectIsForcedRecommendation,
  selectUrls,
} from "redux/app/selectors";

import {
  getPhrases,
  getLocaleKeys,
  getMovieRecommendations,
  isOfflineVersion,
  isBrVersion,
} from "constants/index";
import Header from "components/Header";
import SmoothSwitch from "components/SmoothSwitch";
import Slide from "components/Slide";
import CanvasReveal from "components/CanvasReveal";
import usePreloadedImgs from "utils/hooks/usePreloadedImgs";
import SmartPicker from "components/CharacterPicker/SmartPicker";

import useLoadTracker, {
  Actions as LTActions,
} from "utils/hooks/useLoadTracker";
import { delay, randomPickUnique, isMobile } from "utils/index";
import { ReactComponent as DineyLogo } from "static/disney.svg";
// import { ReactComponent as FinalLogos } from "static/final-logos.svg";
import { ReactComponent as WhatsappLogo } from "static/whatsapp.svg";
import { ReactComponent as TwitterLogo } from "static/twitter.svg";
import { ReactComponent as LinkLogo } from "static/link.svg";
import { ReactComponent as ShareLogo } from "static/share.svg";
import { ReactComponent as CloseIcon } from "static/close.svg";
import { ReactComponent as ShareImage } from "static/share_image.svg";

import { useSnackbar } from "react-simple-snackbar";
import { generateShareLink } from "utils";
import css from "./results.module.scss";

import {
  logPageView,
  trackEvent,
  Events,
} from "utils/analytics/analyticsService";

// import { recommendations as mockedRecs } from "mocks/index";

const block = "results";

const Results = () => {
  let recsFromStore = useSelector(selectRecommendations);
  // let recsFromStore = mockedRecs;
  const copy = useSelector(selectCopy);
  const username = useSelector(selectUsername);
  const urls = useSelector(selectUrls);
  const history = useHistory();
  const Paths = useSelector(selectRoutePaths);
  const isForcedRec = useSelector(selectIsForcedRecommendation);
  const phrases = getPhrases();
  const movieRecList = getMovieRecommendations();
  const { dispNameKey, linkKey, assetKey } = getLocaleKeys();
  const [recommendations, setRecommendations] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [testMode, setTestMode] = useState(false);
  const [movieRecs, setMovieRecs] = useState(false);
  const [surpriseContent, setSurpriseContent] = useState(false);
  const [isShareOption, setIsShareOption] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [preload, imagesLoaded, failedImgs] = usePreloadedImgs();
  const [loadingState, loadingDispatch] = useLoadTracker(1);
  const {
    missingImg,
    baseCharacterImgUrl,
    baseRecommendationImgUrl,
  } = useSelector(selectImgPaths);

  const headerText1 = copy.result_1;
  const headerRecommended = copy.recommended_content;
  const headerSurprise = copy.reveal_surprise;
  const replay = copy.replay;
  const knowMoreCta = copy.results_cta;
  const knowMoreLink = copy.results_cta_link;
  const qrTitle = copy.qr_title;
  const surpriseAction = isMobile()
    ? copy.surprise_action
    : copy.surprise_action_web;

  const traitMap = {
    Brain: "First Stream",
    Heart: "Original",
    Energy: "Binge Watch",
  };

  useEffect(() => {
    if (!movieRecs || !surpriseContent || !recsFromStore || testMode) {
      return;
    }
    const characters = `${recsFromStore.Brain.Character.Character}_Brain,${recsFromStore.Energy.Character.Character}_Energy,${recsFromStore.Heart.Character.Character}_Heart`;
    const actionParameters = `${recsFromStore.Brain.Character.Character}_Brain|${recsFromStore.Energy.Character.Character}_Energy|${recsFromStore.Heart.Character.Character}_Heart`;
    const films = `R1_${movieRecs[0].name},R2_${movieRecs[1].name},R3_${movieRecs[2].name},S_${surpriseContent.Name}`;
    logPageView({ characters, actionParameters, films });
  }, [movieRecs, surpriseContent, recsFromStore, testMode]);

  const [open] = useSnackbar();

  const movieRecMap = {
    Brain: copy.result_first_stream,
    Heart: copy.result_original,
    Energy: copy.result_binge_watch,
  };

  const getAppLink = () => {
    return urls.host;
  };

  const buildAsset = () => {
    function download(datauri) {

      // Chrome for iOS
      if(/CriOS/i.test(navigator.userAgent) && /iphone|ipod|ipad/i.test(navigator.userAgent)) {
        let image = base64ToArrayBuffer(datauri.replace("data:image/octet-stream;base64,", ""));
        saveByteArray("disneyplus.png", image);
      } else {
        // Every other browser
        let a = document.createElement("a");
        a.href = datauri;
        a.target = "_blank";
        a.download = "disneyplus.png";
        document.body.appendChild(a);
        a.click();
      }
    }

    function saveByteArray(imageName, byte) {
      var blob = new Blob([byte], {type: "image/png"});
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      
      var fileName = imageName;
      link.download = fileName.substr(fileName.lastIndexOf('/') + 1);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    function base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    }

    function finishAssetAndDownload(images) {
      // Setup canvas
      let canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        svgImg = document.createElement("img");
      canvas.width = 1080;
      canvas.height = 1920;

      // get char names
      const brainRec = recommendations.Brain.Character[dispNameKey];
      const heartRec = recommendations.Heart.Character[dispNameKey];
      const energyRec = recommendations.Energy.Character[dispNameKey];

      // images position and size
      const x = 709;
      const y_base = 451;
      const y_step = 321;
      const i_height = 270;
      const i_width = 270;

      // Load SVG
      svgImg.src = isBrVersion()
        ? "assets/story-br.svg"
        : "assets/story-ar.svg";
      svgImg.addEventListener("load", function () {
        setTimeout(() => {
          // Draw base SVG Image
          ctx.drawImage(svgImg, 0, 0);

          // Draw 3 char names in custom white font
          ctx.fillStyle = "#FFFFFF";
          ctx.font = "65px Avenir-Heavy";
          ctx.fillText(brainRec, 165, 643);
          ctx.fillText(heartRec, 165, 962);
          ctx.fillText(energyRec, 165, 1280);
          // draw each char image
          ctx.drawImage(images[0], x, y_base, i_width, i_height);
          ctx.drawImage(images[1], x, y_base + y_step, i_width, i_height);
          ctx.drawImage(
            images[2],
            x,
            y_base + y_step + y_step,
            i_width,
            i_height
          );

          // start download
          download(
            canvas
              .toDataURL("image/png")
              .replace("image/png", "image/octet-stream")
          );
        }, 200);
      });
    }

    // load and wait for all images to load then calls callback
    function loadImages(urls, onAllLoaded) {
      var i,
        numLoading = urls.length;
      const images = [];
      const onload = () => --numLoading === 0 && onAllLoaded(images);
      for (i = 0; i <= urls.length; i++) {
        const img = (images[i] = new Image());
        img.crossOrigin = "Anonymous";
        img.src = urls[i] + "?cb=true";
        img.onload = onload;
      }
    }

    // get char image URLs
    let imageURLs = getCharImageUrls(recommendations);
    // Load and draw char images and then start download
    loadImages(imageURLs, finishAssetAndDownload);
  };

  const shareOption = () => {
    navigator
      .share({
        text: phraseToShare(),
        title: document.title,
      })
      .then(() => {
        // console.log("Thanks for sharing!");
      })
      .catch(console.error);

    trackEvent(Events.name.linkClick, `share`);
  };

  const linkClipboard = () => {
    navigator.clipboard
      .writeText(getAppLink())
      .then(() => {
        open(copy.share.clipboard);
        trackEvent(Events.name.linkClick, `copyLink`);
      })
      .catch((e) => {
        console.log("link not copied", e);
      });
  };

  const getWhasappLink = () => {
    return isMobile()
      ? `whatsapp://send?text=${phraseToShare()}`
      : `https://web.whatsapp.com/send?text=${window.encodeURI(
          phraseToShare()
        )}`;
  };

  useEffect(() => {
    if (navigator.share) {
      setIsShareOption(true);
    } else {
      // Fallback
      setIsShareOption(false);
    }
  }, []);

  /**
   * This effect handles preloading of images.
   */
  useEffect(() => {
    if (!loadingState.isLoading || recommendations !== null) {
      return;
    }
    const isTesting = Array.isArray(history.location.pathname.match("test"));

    if (isTesting) {
      try {
        const searchString = window.decodeURI(history.location.search);
        const match = searchString.match(/{(.*?)}/);
        if (Array.isArray(match)) {
          const recs = JSON.parse(match[0]);
          const sp = new SmartPicker({ maxIterations: 12 });
          // eslint-disable-next-line
          recsFromStore = sp.parseForcedRecommendations(recs);
          setTestMode(true);
        }
      } catch (e) {
        console.log("Check url parameters");
      }
    }
    if (recsFromStore) {
      const images = Object.values(recsFromStore).map(
        (r) => `${baseCharacterImgUrl}${r.Character.Image}`
      );
      setRecommendations(recsFromStore);
      const surpriseList = movieRecList.filter(
        (m) => m.Category === "Surprise"
      );
      const surpriseRec = randomPickUnique(surpriseList, 1)[0];
      setSurpriseContent(surpriseRec);
      preload(images);
    } else {
      if (!redirect) {
        history.push(Paths.root);
        setRedirect(true);
      }
    }
  }, [
    recsFromStore,
    preload,
    loadingState,
    recommendations,
    history,
    redirect,
    baseCharacterImgUrl,
    baseRecommendationImgUrl,
    movieRecList,
    assetKey,
    Paths.root,
  ]);

  /**
   * This effect monitors imagesLoaded
   */
  useEffect(() => {
    const imagesReady = async () => {
      await delay(500);
      loadingDispatch(LTActions.assetLoaded());
    };
    if (imagesLoaded) {
      imagesReady();
    }
  }, [imagesLoaded, loadingDispatch]);

  useEffect(() => {
    if (!recommendations) return;
    const movies = Object.keys(recommendations).map((trait, idx) => {
      const r = recommendations[trait];
      const { Studio } = r;
      const category = traitMap[trait];
      const options = movieRecList.filter(
        (m) => m.Studio === Studio && m.Category === category
      );
      console.log(trait, Studio, category);
      console.log(options);

      const selected = randomPickUnique(options, 1)[0];
      const moviePosterUrl = window.encodeURI(
        `${baseRecommendationImgUrl}${selected[assetKey]}`
      );
      return {
        name: selected[dispNameKey],
        link: selected[linkKey],
        image: moviePosterUrl || "/assets/movie.jpeg",
        category,
        trait,
        studio: Studio,
      };
    });
    setMovieRecs(movies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendations]);

  const replayExperience = () => {
    trackEvent(Events.name.buttonClick, Events.action.playAgain);
    let next = Paths.picker;
    if (isForcedRec || isOfflineVersion()) {
      next = Paths.root;
    }
    history.replace(next);
  };

  const renderQRCode = () => {
    if (!recsFromStore) {
      return;
    }
    const shareLink = generateShareLink({
      brain: recsFromStore.Brain.Character.Character,
      energy: recsFromStore.Energy.Character.Character,
      heart: recsFromStore.Heart.Character.Character,
      redirect: true,
      baseUrl: getAppLink(),
    });
    const maxWidth = 500;
    const relSize = window.innerWidth * 0.5;
    const qrSize = relSize > maxWidth ? maxWidth : relSize;
    return (
      <div className={css[`${block}__main__qr`]}>
        <span className={css[`${block}__main__text-a`]}>{qrTitle}</span>
        <QRCode
          value={shareLink}
          bgColor={"#ffffff"}
          fgColor={"#000000"}
          size={qrSize}
        />
      </div>
    );
  };

  const renderRecMovies = () => {
    return movieRecs.map((rec, idx) => {
      // console.log(rec);
      const { trait, image, name } = rec;
      const traitIcon = `/assets/${trait.toLowerCase()}-black-icon.svg`;
      return (
        <div
          key={`${trait}-${idx}`}
          className={css[`${block}__main__movie-rec`]}
        >
          <span className={css[`${block}__main__movie-rec__title`]}>
            {movieRecMap[trait]}
          </span>
          <div
            key={`${trait}-${idx}`}
            className={css[`${block}__main__movie-rec__frame`]}
          >
            <img src={image} alt={name} />
            <div className={css[`${block}__main__movie-rec__footer`]}>
              <span className={css[`${block}__main__movie-rec__name`]}>
                {name}
              </span>
              <img
                className={css[`${block}__main__movie-rec__trait`]}
                src={traitIcon}
                alt={trait}
              />
            </div>
          </div>
        </div>
      );
    });
  };

  // helper for mapping char iamge URLs to an array
  const getCharImageUrls = (recs) => {
    return Object.keys(recs).map((trait, idx) => {
      const r = recs[trait];
      const { Character: char } = r;
      const image = char.Image ? char.Image : null;

      const notLoaded = failedImgs
        .map((img) => {
          const failed = img.match(image);
          return Array.isArray(failed);
        })
        .filter((status) => status === true);

      const imageSrc =
        notLoaded.length > 0 || !image
          ? `${missingImg}`
          : `${baseCharacterImgUrl}${image}`;

      return imageSrc;
    });
  };

  const renderRecommendations = (recs) => {
    return Object.keys(recs).map((trait, idx) => {
      const r = recs[trait];
      const { Character: char } = r;
      const image = char.Image ? char.Image : null;

      const notLoaded = failedImgs
        .map((img) => {
          const failed = img.match(image);
          return Array.isArray(failed);
        })
        .filter((status) => status === true);

      const imageSrc =
        notLoaded.length > 0 || !image
          ? `${missingImg}`
          : `${baseCharacterImgUrl}${image}`;

      let charPhrases = phrases.filter(
        (phrase) => phrase.Character === char.Character
      )[0];
      let charPhrase = "";
      if (charPhrases) {
        charPhrase = charPhrases[trait];
      } else {
        console.log("Check names for:", char.Character);
      }
      const traitIcon = `/assets/${trait.toLowerCase()}-icon.svg`;
      const traitIntro = copy[`${trait.toLowerCase()}_intro`];
      return (
        <div key={char.id} className={css[`${block}__main__rec`]}>
          <div className={css[`${block}__main__rec__trait`]}>
            <img src={traitIcon} alt={trait} />
            <span>{traitIntro}</span>
          </div>
          <div className={css[`${block}__main__rec__frame`]} />
          <div className={css[`${block}__main__rec__text`]}>
            <div className={css[`${block}__main__rec__inner`]}>
              <span className={css[`${block}__main__rec__inner__name`]}>
                {char[dispNameKey]}
              </span>
              <span className={css[`${block}__main__rec__inner__phrase`]}>
                {charPhrase}
              </span>
            </div>
          </div>
          <img src={imageSrc} alt={char[dispNameKey]} />
        </div>
      );
    });
  };

  const renderShare = () => {
    return (
      <div className={css[`${block}__main__container__footer__share`]}>
        <div className={css[`${block}__main__container__footer__share__item`]}>
          <a
            href={`${getWhasappLink()}`}
            target="blank"
            data-action="share/whatsapp/share"
            rel="noopener noreferrer"
            onClick={() => trackEvent(Events.name.linkClick, `whatsapp`)}
          >
            <WhatsappLogo />
          </a>
        </div>
        <div
          className={css[`${block}__main__container__footer__share__line`]}
        />
        <div className={css[`${block}__main__container__footer__share__item`]}>
          <a
            href={`https://twitter.com/intent/tweet?text=${phraseToShare()}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackEvent(Events.name.linkClick, `twitter`)}
          >
            <TwitterLogo />
          </a>
        </div>
        <div
          className={css[`${block}__main__container__footer__share__line`]}
        />
        <div
          className={[
            css[`${block}__main__container__footer__share__item`],
            !isShareOption
              ? css[`${block}__main__container__footer__share__item__last`]
              : "",
          ].join(" ")}
        >
          <div onClick={() => linkClipboard()}>
            <LinkLogo />
          </div>
        </div>
        <div
          className={
            css[`${block}__main__container__footer__share__line__image`]
          }
        />
        <div
          className={
            css[`${block}__main__container__footer__share__item_container`]
          }
        >
          <div
            className={css[`${block}__main__container__footer__share__item`]}
            onClick={() => buildAsset()}
          >
            <ShareImage />
          </div>
        </div>
      </div>
    );
  };

  const phraseToShare = () => {
    const brainRec = recommendations.Brain.Character[dispNameKey];
    const heartRec = recommendations.Heart.Character[dispNameKey];
    const energyRec = recommendations.Energy.Character[dispNameKey];

    const msg = `${copy.share.phraseInit}${brainRec}, ${heartRec}${copy.share.phraseAnd}${energyRec}${copy.share.phraseEnd}`;
    return `${msg}${getAppLink()}`;
  };
  const fetchPreloader = () => {
    return <div className={css[`${block}__loader`]}></div>;
  };
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const fetchContent = () => {
    const desktop_class = [
      css[`${block}__desktop`],
      css[`${block}__main__movie-rec__static`],
    ].join(" ");
    return (
      <div className={css[`${block}__main__container`]}>
        <div className={css[`${block}__main__container__recommendations`]}>
          {renderRecommendations(recommendations)}
        </div>
        <div
          className={css[`${block}__main__container__movie-recommendations`]}
        >
          {!isOfflineVersion() && (
            <div className={css[`${block}__main__container__btn-container`]}>
              <button
                className={css[`${block}__btn__share`]}
                onClick={openModal}
              >
                {copy.share.open_share_modal_btn}
              </button>
            </div>
          )}

          <span className={css[`${block}__main__text-a`]}>
            {headerRecommended}
          </span>
          <Slide className={css[`${block}__mobile`]}>{renderRecMovies()}</Slide>
          <div className={desktop_class}>{renderRecMovies()}</div>
        </div>
        <div>
          <span className={css[`${block}__main__text-a`]}>
            {headerSurprise}
          </span>
          <div className={css[`${block}__main__canvas-wrapper`]}>
            <CanvasReveal
              className={css[`${block}__main__canvas`]}
              img={window.encodeURI(
                `${baseRecommendationImgUrl}${surpriseContent[assetKey]}`
              )}
            />
            <p>{surpriseAction}</p>
          </div>
          {isOfflineVersion() && renderQRCode()}
          <div className={css[`${block}__main__container__footer`]}>
            <DineyLogo className={css[`${block}__main__disney-logo`]} />
            <div className={css[`${block}__main__btns_container`]}>
              {!isOfflineVersion() && (
                <a
                  className={css[`${block}__btn`]}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={knowMoreLink}
                >
                  {knowMoreCta}
                </a>
              )}
              <button
                className={css[`${block}__btn__replay`]}
                onClick={replayExperience}
              >
                {replay}
              </button>
            </div>
            <strong>{copy.legal.line_1}</strong>
            <div className={css[`${block}__main__container__footer__links`]}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={copy.legal.terms_link}
              >
                {copy.legal.terms}
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={copy.legal.privacy_link}
              >
                {copy.legal.privacy}
              </a>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href={copy.legal.ad_preferences.url}
              >
                {copy.legal.ad_preferences.text}
              </a>
            </div>
            <span>{copy.legal.availability}</span>
            <span>{copy.legal.copyright}</span>
          </div>
        </div>
        {showModal ? (
          <div className={css[`${block}__modal`]}>
            <div className={css[`${block}__modal__container`]}>
              <div className={css[`${block}__modal__container__close`]}>
                <button onClick={closeModal}>
                  <CloseIcon />
                </button>
              </div>
              <div className={css[`${block}__modal__title`]}>
                {copy.share.modal_title}
              </div>
              {renderShare()}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };
  const headerText = username ? `${username}, ${headerText1}` : headerText1;
  return (
    <>
      <div className={css[`${block}`]}>
        <div className={css[`${block}__top`]}>
          <Header />
          <span className={css[`${block}__top__text-a`]}>{headerText}</span>
        </div>
        <div className={css[`${block}__main`]}>
          <SmoothSwitch
            showContent={!loadingState.isLoading}
            placeholder={fetchPreloader}
            content={fetchContent}
          ></SmoothSwitch>
        </div>
      </div>
    </>
  );
};
export default Results;

// http://localhost:3000/?%7B%22Brain%22:%22Mike%20Wazowski%22,%22Energy%22:%22Peter%20pan%22,%22Heart%22:%22The%20Mandalorian%22%7D&redirect
