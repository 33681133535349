// App Global Reducers
import { combineReducers } from "redux";
import { systemReducer } from "redux/system/reducers";
import appReducer from "redux/app/reducer";

const rootReducer = combineReducers({
  systemReducer,
  appReducer,
});

export default rootReducer;
