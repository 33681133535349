import React, { useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import SmoothSwitch from "components/SmoothSwitch";
import Animator, {
  useAnimator,
  Actions as AnimActions,
} from "components/Animator";
import {
  selectAnimations,
  selectRoutePaths,
  selectCopy,
} from "redux/app/selectors";
import useLoadTracker, {
  Actions as LTActions,
} from "utils/hooks/useLoadTracker";
import Header from "components/Header";
import { loadFile } from "utils";
import { logPageView } from "utils/analytics/analyticsService";

import css from "./analyzing.module.scss";
const block = "analyzing";

let timeout;

const Analyzing = () => {
  const history = useHistory();
  const copy = useSelector(selectCopy);
  const Paths = useSelector(selectRoutePaths);
  const [loadingState, loadingDispatch] = useLoadTracker(1);
  const animationDataRef = useRef(null);
  const [animationState, dispatchAnimation] = useAnimator();
  const animations = useSelector(selectAnimations);

  const headerText1 = copy.decoding_1;
  const headerText2 = copy.decoding_2;
  const legalCopy = copy.legal;

  useEffect(() => {
    logPageView();
  }, []);

  const onComplete = useCallback(() => {
    history.push(Paths.results);
  }, [history, Paths.results]);

  useEffect(() => {
    const loadAnimation = async function (file) {
      let animation;
      try {
        animation = await loadFile(file);
        animationDataRef.current = animation;
        loadingDispatch(LTActions.assetLoaded());
        dispatchAnimation(AnimActions.loop(false));
        dispatchAnimation(AnimActions.setSpeed(1.5));
        dispatchAnimation(AnimActions.play());
      } catch (e) {
        console.log("Animation asset failed", e);
      }
    };
    loadAnimation(animations.analysis.path);
    return () => {
      clearTimeout(timeout);
    };
  }, [animationDataRef, animations, loadingDispatch, dispatchAnimation]);

  const fetchPreloader = () => {
    return <div className={css[`${block}__loader`]}></div>;
  };

  const fetchContent = () => {
    return (
      <div className={css[`${block}__main__container`]}>
        <div className={css[`${block}__main__container__animation`]}>
          <Animator
            data={animationDataRef.current}
            config={animationState}
            onComplete={onComplete}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={css[`${block}`]}>
        <div className={css[`${block}__top`]}>
          <Header />
          <span className={css[`${block}__top__text-a`]}>{headerText1}</span>
          <span className={css[`${block}__top__text-b`]}>{headerText2}</span>
        </div>
        <div className={css[`${block}__main`]}>
          <SmoothSwitch
            showContent={!loadingState.isLoading}
            placeholder={fetchPreloader}
            content={fetchContent}
          ></SmoothSwitch>
          <div className={css[`${block}__main__footer`]}>
            <strong>{legalCopy.line_1}</strong>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={legalCopy.terms_link}
            >
              {legalCopy.terms}
            </a>
            <div className={css[`${block}__main__footer__links`]}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={legalCopy.privacy_link}
              >
                {legalCopy.privacy}
              </a>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href={legalCopy.ad_preferences.url}
              >
                {legalCopy.ad_preferences.text}
              </a>
            </div>
            <span>{legalCopy.availability}</span>
            <span>{legalCopy.copyright}</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Analyzing;
