import React from "react";
import css from "./header.module.scss";
import { getLogo } from "constants/index";

const block = "header";

const Header = ({ className }) => {
  const Logo = getLogo();
  return (
    <>
      <div className={[css[`${block}`], className].join(" ")}>
        <div className={css[`${block}__main`]}>
          <Logo className={css[`${block}__main__logo`]} />
        </div>
      </div>
    </>
  );
};
export default Header;
