import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  BrowserRouter as Router,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { selectRoutePaths, selectImgPaths } from "redux/app/selectors";
import { enableScroll, disableScroll } from "utils/index";
import assetPreloader from "utils/assetPreloader";
import { useSnackbar } from "react-simple-snackbar";

import Landing from "components/Landing";
import Analyzing from "components/Analyzing";
import Onboarding from "components/Onboarding";
import Tutorial from "components/Tutorial";
import CharacterPicker from "components/CharacterPicker";
import Results from "components/Results";
import { isTestMode, isOfflineVersion, isBrVersion } from "constants/index";
import css from "./transitions.module.scss";

const fetchPanelTransitions = () => {
  const animationPrefix = `a-route`;

  const transitionNames = {
    appear: css[`${animationPrefix}-appear`],
    appearActive: css[`${animationPrefix}-appear-active`],
    appearDone: css[`${animationPrefix}-appear-done`],
    enter: css[`${animationPrefix}-enter`],
    enterActive: css[`${animationPrefix}-enter-active`],
    enterDone: css[`${animationPrefix}-enter-done`],
    exit: css[`${animationPrefix}-exit`],
    exitActive: css[`${animationPrefix}-exit-active`],
    exitDone: css[`${animationPrefix}-exit-done`],
  };
  const timing = { enter: 600, exit: 600 };
  const transitionProps = {
    timeout: timing,
    classNames: transitionNames,
  };
  return {
    transitionProps,
    animationPrefix,
  };
};

const animatedRoutes = withRouter(({ location }) => {
  const [preloadingStarted, setPreloadingStarted] = useState(false);
  const { transitionProps } = fetchPanelTransitions();
  const paths = useSelector(selectRoutePaths);
  const imgPaths = useSelector(selectImgPaths);

  const [openSnackBar] = useSnackbar();
  useEffect(() => {
    const info = {
      version: isBrVersion() ? "ES" : "BR",
      imgPaths: imgPaths,
    };
    const updateProgress = (msg) => {
      openSnackBar(msg);
    };
    const preloadAssets = async () => {
      const failed = await assetPreloader(updateProgress, info);
      if (failed.length > 0) {
        openSnackBar(`${failed.length} assets were not loaded.`);
        openSnackBar(failed);
      } else {
        openSnackBar("All assets preloaded!");
        window.localStorage.setItem("preloaded", "done");
      }
    };
    const alreadyPreloaded =
      window.localStorage.getItem("preloaded") === "done" ? true : false;
    if (
      isOfflineVersion() &&
      navigator.onLine &&
      !preloadingStarted &&
      !alreadyPreloaded
    ) {
      setPreloadingStarted(true);
      preloadAssets();
    }
    return;
  }, [openSnackBar, imgPaths, preloadingStarted]);
  return (
    <TransitionGroup
      component={null}
      childFactory={(child) =>
        React.cloneElement(child, {
          classNames: transitionProps.classNames,
        })
      }
    >
      <CSSTransition
        key={location.key}
        {...transitionProps}
        onEnter={() => disableScroll()}
        onExited={() => enableScroll()}
      >
        <div>
          <Switch location={location}>
            <Route exact path={paths.root} render={() => <Landing />} />
            <Route exact path={paths.tutorial} render={() => <Tutorial />} />
            <Route exact path={paths.onboard} render={() => <Onboarding />} />
            <Route
              exact
              path={paths.picker}
              render={() => <CharacterPicker />}
            />
            {isTestMode() && (
              <Route
                exact
                path={`${paths.picker}/compare/`}
                render={() => <CharacterPicker />}
              />
            )}
            <Route exact path={paths.analysis} render={() => <Analyzing />} />
            <Route exact path={paths.results} render={() => <Results />} />
            {isTestMode() && (
              <Route
                exact
                path={`${paths.results}/test`}
                render={() => <Results />}
              />
            )}
            <Redirect to={paths.root} />
          </Switch>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
});

const Routes = ({ config, location }) => {
  if (!config) {
    return <></>;
  }
  return <Router>{animatedRoutes({ location })}</Router>;
};

export default Routes;
