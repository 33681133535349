import {
  isBrVersion,
  isProduction,
  isTestMode,
  localStorageKey,
  getPopAgeValidAge,
} from "constants/index";
import { loadFromLocalStorage, isDateLessThan } from "utils";

const logToConsole = (log) => {
  if (isTestMode()) {
    console.log(...log);
  }
};

const addTracker = (a, b, c, d) => {
  if (!shouldLog()) return;
  var prodDomains = isBrVersion()
    ? ["dna.disney.com.br"]
    : ["adn.disneylatino.com"]; // The URLs of the production website.
  var profile = "latam"; // the name of the Tealium profile

  window.utag_cfg_ovrd = { noview: true }; //Cancelamos el trackeo automático por ser en REACT
  a =
    prodDomains.indexOf(document.domain) !== -1
      ? "//tags.disneyinternational.com/tealium/" + profile + "/prod/utag.js"
      : "//tags.disneyinternational.com/tealium/" + profile + "/dev/utag.js";
  b = document;
  c = "script";
  d = b.createElement(c);
  d.src = a;
  d.type = "text/java" + c;
  d.async = true;
  a = b.getElementsByTagName(c)[0];
  a.parentNode.insertBefore(d, a);
  d.addEventListener("load", () => {
    logPageView();
  });

  logToConsole(["Tracker added", prodDomains]);
};

const logPageView = (data = false) => {
  let pageData = {};
  if (isBrVersion()) {
    pageData = {
      siteName: "dnadisneybr",
      country: "br",
      property: "dnadisneybr", //sponsor
      link_name: "",
      actionName: "",
      actionValue: "",
      actionParameters: "",
      films: "",
      characters: "",
    };
  } else {
    pageData = {
      siteName: "adndisney",
      country: "latino",
      property: "adndisney", //sponsor
      link_name: "",
      actionName: "",
      actionValue: "",
      actionParameters: "",
      films: "",
      characters: "",
    };
  }
  if (data !== false) {
    pageData = { ...pageData, ...data };
  }
  window.pageData = pageData;

  if (!window.utag && shouldLog()) {
    addTracker();
    return;
  }

  if (window.utag && isProduction() && shouldLog()) {
    window.utag.view(pageData);
    logToConsole(["pageview tracked with:", pageData]);
  }
};

const shouldLog = () => {
  const popUpAge = getPopAgeValidAge();
  const localStorageObj = loadFromLocalStorage(localStorageKey);
  if (!localStorageObj) return false;
  const isAdult = localStorageObj.value;
  const isDateValid = isDateLessThan(popUpAge, localStorageObj.timestamp);
  return isAdult && isDateValid;
};

const trackEvent = (actionName, actionValue, actionParameters) => {
  let linkData = {
    link_name: "tE",
    actionName: actionName,
    actionValue: actionValue,
    actionParameters: actionParameters,
  };
  if (window.utag && isProduction() && shouldLog()) {
    // if (window.utag) {
    window.utag.link(Object.assign({}, window.pageData, linkData));
    logToConsole(["event tracked", linkData]);
  }
};

const Events = {
  name: {
    buttonClick: "button-click",
    linkClick: "link-click",
  },
  action: {
    characterPick: "characterPick",
    characterPickDetail: "characterPickDetail",
    playAgain: "playAgain",
    skipCharacterSel: "skipCharacterSel",
  },
};

export { logPageView, trackEvent, Events, addTracker };
