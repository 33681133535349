import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import css from "./transitions.module.scss";

const SmoothSwitch = ({
  showContent,
  content,
  placeholder,
  time = 600,
  type = "fade",
}) => {
  const animationPrefix = `smooth-${type}`;
  const transitionNames = {
    enter: css[`${animationPrefix}-enter`],
    enterActive: css[`${animationPrefix}-enter-active`],
    enterDone: css[`${animationPrefix}-enter-done`],
    exit: css[`${animationPrefix}-exit`],
    exitActive: css[`${animationPrefix}-exit-active`],
    exitDone: css[`${animationPrefix}-exit-done`],
  };
  const timing = { enter: time, exit: time };
  const loaderTransitionProps = {
    timeout: timing,
    classNames: transitionNames,
  };

  return (
    <TransitionGroup timeout={timing} component={null}>
      {showContent && (
        <CSSTransition in={showContent} {...loaderTransitionProps}>
          {content()}
        </CSSTransition>
      )}
      {!showContent && (
        <CSSTransition in={!showContent} {...loaderTransitionProps}>
          {placeholder()}
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

export default SmoothSwitch;
