import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Animator, {
  useAnimator,
  Actions as AnimActions,
} from "components/Animator";
import useLoadTracker, {
  Actions as LTActions,
} from "utils/hooks/useLoadTracker";
import {
  selectRoutePaths,
  selectCopy,
  selectAnimations,
} from "redux/app/selectors";
import Header from "components/Header";
import SmoothSwitch from "components/SmoothSwitch";
import { Actions } from "redux/app/actions";
import { loadFile } from "utils";

import { logPageView } from "utils/analytics/analyticsService";

import css from "./onboarding.module.scss";
const block = "onboarding";

const assetsPath = "/assets/";

const Onboarding = () => {
  const copy = useSelector(selectCopy);
  const Paths = useSelector(selectRoutePaths);
  const [loadingState, loadingDispatch] = useLoadTracker(1);
  const [showReady, setShowReady] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const inputRef = useRef(null);
  const continueRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const animationDataRef = useRef(null);
  const [animationState, dispatchAnimation] = useAnimator();
  const animations = useSelector(selectAnimations);

  const headerText1 = copy.name;
  const inputPlaceholder = copy.write_it_here;
  const legalCopy = copy.legal;
  const continueCopy = copy.continue;

  useEffect(() => {
    logPageView();
  }, []);

  useEffect(() => {
    const loadAnimation = async function (file) {
      let animation;
      try {
        animation = await loadFile(file);
        animationDataRef.current = animation;
        loadingDispatch(LTActions.assetLoaded());
        dispatchAnimation(AnimActions.loop(true));
        dispatchAnimation(AnimActions.play());
      } catch (e) {
        console.log("Animation asset failed", e);
      }
    };
    console.log(animations.bubbles.path);
    loadAnimation(animations.bubbles.path);
  }, [animationDataRef, animations, loadingDispatch, dispatchAnimation]);

  const onSubmit = (event) => {
    event.preventDefault();
    const name = inputRef.current.value;
    if (name) {
      dispatch(Actions.setUsername(name));
      setSubmitted(true);
      setTimeout(() => {
        history.push(Paths.tutorial);
      }, 500);
    }
  };

  const onChange = (event) => {
    let name = inputRef.current.value;
    name = name.replace(/[^a-zA-Z]/gi, "");
    inputRef.current.value = name;
    setShowReady(name.length > 0);
  };

  const fetchPreloader = () => {
    return <div className={css[`${block}__loader`]}></div>;
  };

  const fetchContent = () => {
    const continueClassName = [
      css[`${block}__input-continue`],
      showReady ? css["ready"] : "",
    ].join(" ");
    return (
      <div className={css[`${block}__main__container`]}>
        {!submitted && (
          <form onSubmit={onSubmit}>
            <input
              autoComplete="off"
              className={css[`${block}__input`]}
              placeholder={inputPlaceholder}
              ref={inputRef}
              onChange={onChange}
            />
            {showReady && (
              <button className={css[`${block}__input-ready`]}>
                <img src={`${assetsPath}arrow-icon.svg`} alt="ready" />
              </button>
            )}
            <button className={continueClassName} ref={continueRef}>
              {continueCopy}
            </button>
          </form>
        )}
        {submitted && (
          <span className={css[`${block}__submitted`]}>
            <img src={`${assetsPath}check-icon.svg`} alt="submitted" />
          </span>
        )}
        <div className={css[`${block}__main__container__animation`]}>
          <Animator data={animationDataRef.current} config={animationState} />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={css[`${block}`]}>
        <div className={css[`${block}__top`]}>
          <Header />
        </div>
        <div className={css[`${block}__main`]}>
          <span className={css[`${block}__main__text-a`]}>{headerText1}</span>
          <SmoothSwitch
            showContent={!loadingState.isLoading}
            placeholder={fetchPreloader}
            content={fetchContent}
          ></SmoothSwitch>
        </div>

        <div className={css[`${block}__main__footer`]}>
          <strong>{legalCopy.line_1}</strong>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={legalCopy.terms_link}
          >
            {legalCopy.terms}
          </a>
          <div className={css[`${block}__main__footer__links`]}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={legalCopy.privacy_link}
            >
              {legalCopy.privacy}
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href={legalCopy.ad_preferences.url}
            >
              {legalCopy.ad_preferences.text}
            </a>
          </div>
          <span>{legalCopy.availability}</span>
          <span>{legalCopy.copyright}</span>
        </div>
      </div>
    </>
  );
};
export default Onboarding;
