import configAR from "static/config.ar.json";
import configBR from "static/config.br.json";

import { ReactComponent as LogoAR } from "static/disney-ar.svg";
import { ReactComponent as LogoBR } from "static/disney-br.svg";

import phrasesAR from "static/data/phrases.ar.json";
import phrasesBR from "static/data/phrases.br.json";

import movies from "static/data/movies.json";

export const localStorageKey = "isAdult";
export const getPopAgeValidAge = () => {
  const oneHour = 60 * 60 * 1000; // ms
  return oneHour * 4;
};

export const isProduction = () => {
  return process.env.NODE_ENV === "production";
};

export const isTestMode = () => {
  return process.env.REACT_APP_MODE === "test";
};

export const isBrVersion = () => {
  return process.env.REACT_APP_VERSION === "br";
};

export const isOfflineVersion = () => {
  return process.env.REACT_APP_OFFLINE === "true";
};

export const getConfig = () => {
  if (isBrVersion()) {
    return configBR;
  }
  return configAR;
};

export const getLogo = () => {
  if (isBrVersion()) {
    return LogoBR;
  }
  return LogoAR;
};

export const getPhrases = () => {
  if (isBrVersion()) {
    return phrasesBR;
  }
  return phrasesAR;
};

export const getLocaleKeys = () => {
  const keys = {
    dispNameKey: "DisplayNameAR",
    assetKey: "AssetAR",
    linkKey: "LinkAR",
  };
  if (isBrVersion()) {
    return {
      dispNameKey: "DisplayNameBR",
      assetKey: "AssetBR",
      linkKey: "LinkBR",
    };
  }
  return keys;
};

export const getMovieRecommendations = () => {
  const isEnabledKey = isBrVersion() ? "BR" : "ES";
  return movies.filter((m) => m[isEnabledKey] === 1);
};
