import BigNumber from "bignumber.js";
import scoring from "static/data/scoring.json";
import attributes from "static/data/attributes.json";
import { isTestMode } from "constants/index";

// Fine Tunning
// Sample acceptance
const nAcceptedZeroTraits = 0;
const minIterations = 8;
const nAcceptedZeroStudios = 1;
const softMaxIterations = 6;
let hardMaxIterations = 12;

let traitMultiplier = 1;

/**
 * SmartPicker
 * For usage instructions read tests.
 */
class SmartPicker {
  constructor({ testMode = false, maxIterations = 12 } = {}) {
    this.initialized = false;
    this.logDisabled = !isTestMode();
    this.studios = attributes.studios;
    this.traits = attributes.traits;
    this.currentOptions = [];
    this.characterPool = [];
    this.originalCharacterPool = [];
    this.selectedCharacters = [];
    this.selectedStudios = [];
    this.currentTrait = null;
    this.scoreCounter = {};
    this.recommendedCharacters = [];
    this.shownStudios = [];
    this.initialStudiosOrder = [];
    this.iterations = 0;
    hardMaxIterations = maxIterations;
    this.error = { status: false, events: [] };
    this.history = {
      events: [],
      guidedPicks: false,
      forced: false,
      iterations: 0,
    };
    this.testMode = testMode;
    this.allowedResultsPool = {
      Heart: [],
      Brain: [],
      Energy: [],
    };
    this.fullAllowedResultsPool = {
      Heart: [],
      Brain: [],
      Energy: [],
    };
    this.traitCounters = {
      Heart: 0,
      Brain: 0,
      Energy: 0,
    };
    this.targetTraitScore = {
      Heart: 0,
      Brain: 0,
      Energy: 0,
    };

    this.init();
  }

  init() {
    if (
      !scoring ||
      !this.traits ||
      !this.studios ||
      !Array.isArray(scoring) ||
      !Array.isArray(this.traits) ||
      !Array.isArray(this.studios)
    ) {
      this.error.status = true;
      this.error.events.push({
        msg: "Init failed",
        traits: this.traits,
        studios: this.studios,
        scoring,
      });
      console.log(this.error);
      return;
    }
    this.initScoreCounter();
    this.characterPool = scoring.map((char, idx) => {
      return { ...char, id: idx };
    });
    this.originalCharacterPool = [...this.characterPool];
    Object.freeze(this.originalCharacterPool);

    this.characterPool.forEach((char) => {
      this.traits.forEach((trait) => {
        if (char[trait] !== 0) {
          this.fullAllowedResultsPool[trait].push(char);
        }
      });
    });

    this.initialStudiosOrder = this.shuffleArray(this.studios);

    this.initialized = true;
  }

  initScoreCounter() {
    const columns = [...this.traits, ...this.studios];
    this.scoreCounter = columns.reduce((obj, col) => {
      obj[col] = 0;
      return obj;
    }, {});
  }

  reset() {}

  logToConsole(log) {
    if (!this.logDisabled) {
      console.log(...log);
    }
  }

  shuffleArray(arr) {
    let shuffled = [...arr]
      .map((a) => ({ sort: Math.random(), value: a }))
      .sort((a, b) => a.sort - b.sort)
      .map((a) => a.value);
    return shuffled;
  }

  randomPickUnique(arr, nPicks = 1) {
    const ar = [...arr];
    const picks = [];
    let remPicks = nPicks;

    while (remPicks) {
      const pickIdx = Math.floor(Math.random() * ar.length);
      picks.push(ar[pickIdx]);
      ar.splice(pickIdx, 1);
      remPicks -= 1;
    }
    return picks;
  }

  randomPickWithUniqueStudioIfPossible(arr) {
    let ar = [...arr];
    const picks = [];

    let remPicks = 2;

    while (remPicks) {
      let pickIdx;
      if (picks.length > 0) {
        const charStudio = this.findCharacterStudio(picks[0]);
        const newAr = this.removeStudioFromPool(ar, charStudio);
        if (newAr.length > 0) {
          picks.push(this.randomPickUnique(newAr, 1)[0]);
        } else {
          picks.push(this.randomPickUnique(ar, 1)[0]);
          console.log("Failed unique studio:", ar, newAr);
        }
      } else {
        pickIdx = Math.floor(Math.random() * ar.length);
        picks.push(ar[pickIdx]);
        ar.splice(pickIdx, 1);
      }
      remPicks -= 1;
    }
    return picks;
  }

  pickWithUniqueStudioAndTrait(arr, targetTrait) {
    const ar = [...arr];
    let pick = null;

    let remPicks = 1;

    while (remPicks) {
      if (ar.length < 3) {
        pick = null;
        break;
      }
      const pickIdx = Math.floor(Math.random() * ar.length);
      const studio = this.findCharacterStudio(ar[pickIdx]);
      const traitTestPass = Boolean(ar[pickIdx][targetTrait] !== 0);
      if (!this.selectedStudios.includes(studio) && traitTestPass) {
        pick = ar[pickIdx];
        ar.splice(pickIdx, 1);
        remPicks -= 1;
      }
    }
    return pick;
  }

  pickOptions(arr, nPicks) {
    const guidedPick = this.iterations >= softMaxIterations;

    // First rounds must include all 4 studios.
    if (this.initialStudiosOrder.length > 0) {
      const options = [];
      const studioA = this.initialStudiosOrder.pop();
      const studioB = this.initialStudiosOrder.pop();
      const studioAPool = arr.filter((c) => c[studioA] === 1);
      const studioBPool = arr.filter((c) => c[studioB] === 1);
      options.push(this.randomPickUnique(studioAPool, 1)[0]);
      options.push(this.randomPickUnique(studioBPool, 1)[0]);
      this.logToConsole(["Initial studios: ", options]);
      this.logToConsole(["Next studios: ", this.initialStudiosOrder]);
      return options;
    }

    if (guidedPick) {
      let newPool = [];
      const targetStudios = [];
      const targetTraits = [];
      // Find studios to target
      this.studios.forEach((studio) => {
        if (this.scoreCounter[studio] === 0) {
          targetStudios.push(studio);
        }
      });
      // find traits to target
      this.traits.forEach((trait) => {
        if (this.scoreCounter[trait] === 0) {
          targetTraits.push(trait);
        }
      });

      if (targetStudios.length > nAcceptedZeroStudios) {
        arr.forEach((char) => {
          targetStudios.forEach((studio) => {
            if (char[studio] === 1) {
              newPool.push(char);
            }
          });
        });
      } else if (targetTraits.length > 0) {
        arr.forEach((char) => {
          targetTraits.forEach((trait) => {
            if (char[trait] === 1) {
              newPool.push(char);
            }
          });
        });
      } else {
        const tied = this.checkStudioTie(this.studioCountMap);
        if (tied.length > 1) {
          const options = [];
          const studioAPool = arr.filter((c) => c[tied[0].studio] === 1);
          const studioBPool = arr.filter((c) => c[tied[1].studio] === 1);
          options.push(this.randomPickUnique(studioAPool, 1)[0]);
          options.push(this.randomPickUnique(studioBPool, 1)[0]);
          this.logToConsole(["Tiebreak:", tied, options]);
          return options;
        }
      }

      const historyEvent = { guidedPick: true, targetStudios, newPool, arr };
      // console.log("guided pick");
      this.history.events.push(historyEvent);
      this.history.guidedPicks = true;
      newPool = newPool.length >= 2 ? newPool : arr;
      return this.randomPickUnique(newPool, nPicks);
    } else {
      return this.randomPickWithUniqueStudioIfPossible(arr);
    }
  }

  // Calculus related functions

  findDistance(a, b) {
    const ax = new BigNumber(a[0]);
    const ay = new BigNumber(a[1]);
    const bx = new BigNumber(b[0]);
    const by = new BigNumber(b[1]);
    const x = ax.minus(bx).exponentiatedBy(2);
    const y = ay.minus(by).exponentiatedBy(2);
    return x.plus(y).squareRoot();
  }

  generateDistanceMatrix(selected, pool) {
    const dMatrix = selected.reduce((matrix, sChar, idx) => {
      const { PC0: ax, PC1: ay } = sChar;
      const aPoint = [ax, ay];
      const dfRow = pool.reduce((row, unChar) => {
        const { PC0: bx, PC1: by } = unChar;
        const bPoint = [bx, by];
        row.push(this.findDistance(aPoint, bPoint));
        return row;
      }, []);
      matrix[idx] = dfRow;
      return matrix;
    }, []);
    return dMatrix;
  }

  // Filtering related functions

  findCharacterStudio(char) {
    return this.studios.filter((studio) => char[studio] === 1)[0];
  }

  removeWorstStudioFrom(pool) {
    const chars = [...pool];
    const finalPool = [];
    const sortedStudios = this.studios
      .map((s) => {
        return {
          name: s,
          count: this.scoreCounter[s],
        };
      })
      .sort((a, b) => a.count - b.count);

    // const bla = [...sortedStudios];
    // console.log(bla);
    sortedStudios.shift();
    // console.log(sortedStudios);
    const allowedStudios = sortedStudios.map((s) => s.name);

    chars.forEach((char) => {
      allowedStudios.forEach((studio) => {
        if (char[studio] === 1) {
          finalPool.push(char);
        }
      });
    });
    return finalPool;
  }

  removeCharsFromPool(charsToRemove, charPool) {
    let pool = [...charPool];
    if (
      !charsToRemove ||
      !Array.isArray(charsToRemove) ||
      !charPool ||
      !Array.isArray(charPool)
    ) {
      console.log({ charsToRemove }, "where not removed from", { charPool });
      return pool;
    }
    charsToRemove.forEach((charToRemove) => {
      const idx = pool.findIndex((char) => char.id === charToRemove.id);
      if (idx >= 0) {
        pool.splice(idx, 1);
      }
    });
    return pool;
  }

  removeStudioFromPool(pool, studio) {
    const updatedPool = [...pool];
    const newPool = updatedPool.filter((char) => char[studio] !== 1);
    return newPool;
  }

  filterSelectedByTrait(selectedChars, trait) {
    const chars = [...selectedChars];
    let targetScore = 1;
    if (this.traitCounters[trait] !== 0) {
      targetScore =
        this.scoreCounter[trait] /
        (this.traitCounters[trait] * traitMultiplier);
    }
    // console.log(trait, targetScore);
    if (targetScore <= 1.65) {
      targetScore = 1;
    }
    if (targetScore >= 1.66 && targetScore <= 2.32) {
      targetScore = 2;
    }
    if (targetScore >= 2.33) {
      targetScore = 3;
    }
    this.targetTraitScore[trait] = targetScore;
    // console.log(trait, targetScore);

    return chars.filter((char) => char[trait] === targetScore);
  }

  findMinDistanceIndex(dMatrix) {
    let characterIdx = null;
    let min = null;
    // Transpose matrix
    const transDMatrix = dMatrix[0].map((_, colIndex) =>
      dMatrix.map((row) => row[colIndex])
    );

    transDMatrix.forEach((unChar, idx) => {
      const sorted = unChar.sort((a, b) => {
        return a.minus(b);
      }); //.map(d => d.toNumber());
      // First iteration
      min = min === null ? sorted[0] : min;
      characterIdx = characterIdx === null ? idx : characterIdx;
      if (sorted[0].lt(min)) {
        min = sorted[0];
        characterIdx = idx;
      }
    });
    // console.log({ min: min.toNumber(), characterIdx });
    return characterIdx;
  }

  // Analysis and formatting functions

  processSelection(winner, loser) {
    this.selectedCharacters.push(winner);
    this.iterations += 1;
    const currentScore = { ...this.scoreCounter };

    const traitCount = [...this.traits].reduce((obj, col) => {
      obj[col] = winner[col] * traitMultiplier + currentScore[col];
      return obj;
    }, {});

    this.traits.forEach((trait) => {
      if (winner[trait] !== 0) {
        this.traitCounters[trait] += 1;
      }
    });

    const studioCount = [...this.studios].reduce((obj, col) => {
      obj[col] = winner[col] + currentScore[col];
      return obj;
    }, {});

    const updatedScore = { ...traitCount, ...studioCount };
    this.scoreCounter = updatedScore;
    this.logToConsole(["score", this.scoreCounter]);
    this.logToConsole(["counter", this.traitCounters]);
    this.history.events.push({
      iteration: this.iterations,
      winner,
      loser,
      score: updatedScore,
    });
  }

  formatRecommendation(char, studio, trait = null) {
    return {
      studio,
      trait,
      character: char,
    };
  }

  presentResults(result, forced = false) {
    this.history.recommendedCharacters = [...result];
    this.history.forced = forced;
    this.history.iterations = this.iterations;
    this.history.finalScore = { ...this.scoreCounter };
    // console.log(this.history);
    if (this.error.status) {
      console.log("Errors:", this.error.events);
    }
  }

  /**
   * This method should always return 3 formatted
   * and result ready recommendations.
   * If provided pool is not big enough it'll search for results
   * in the initial un-filtered pool
   */
  fullRandomRecommendationsFrom(pool = null) {
    const forcedRecommendations = [];
    const forcedTraits = [...this.traits];
    const poolToChoose = pool ? pool : [...this.originalCharacterPool];
    forcedTraits.forEach((trait) => {
      let r = this.pickWithUniqueStudioAndTrait(poolToChoose, trait);
      if (!r) {
        r = this.pickWithUniqueStudioAndTrait(
          [...this.originalCharacterPool],
          trait
        );
      }
      const studio = this.findCharacterStudio(r);
      this.selectedStudios.push(studio);
      forcedRecommendations.push(this.formatRecommendation(r, studio, trait));
    });
    this.error.status = true; // We shoult not force.
    this.error.events.push({ msg: "Full random recommendation" });
    return forcedRecommendations;
  }

  /**
   * This method tries to find a single proper recommendation from the
   * provided pool. Where proper means it
   * takes into account: this.currentTrait and this.selectedStudios
   * This method may return null.
   */
  attemptForceOneRecommendationFrom(pool) {
    let updatedPool = [...pool];
    this.error.status = true; // We shoult not force.
    this.error.events.push({ msg: "Forcing one recommendation.", pool });

    const recommendation = this.pickWithUniqueStudioAndTrait(
      updatedPool,
      this.currentTrait
    );
    if (!recommendation) {
      return null;
    }
    const studio = this.findCharacterStudio(recommendation);
    const r = this.formatRecommendation(
      recommendation,
      studio,
      this.currentTrait
    );
    return r;
  }

  checkStudioTie(studioCountMap) {
    const studioCounts = studioCountMap.map((sm) => sm.count);
    const min = Math.min(...studioCounts);
    const tied = studioCountMap.reduce((tied, studio) => {
      if (studio.count === min) {
        return [...tied, studio];
      } else {
        return tied;
      }
    }, []);
    return tied;
  }

  isReadyToAnalyze() {
    if (!this.initialized) {
      console.log("SmartPicker: Not initialized.");
      return false;
    }

    const nZeroTraits = this.traits.reduce(
      (nZeroTraits, trait) =>
        this.scoreCounter[trait] === 0 ? (nZeroTraits += 1) : nZeroTraits,
      0
    );
    const traitsCheck = nZeroTraits <= nAcceptedZeroTraits;

    const nZeroStudios = this.studios.reduce(
      (nZeroStudios, studio) =>
        this.scoreCounter[studio] === 0 ? (nZeroStudios += 1) : nZeroStudios,
      0
    );
    const studiosCheck = nZeroStudios <= nAcceptedZeroStudios;

    const iterationsCheck = this.iterations >= minIterations;

    const studioCountMap = this.studios.map((s) => ({
      studio: s,
      count: this.scoreCounter[s],
    }));
    this.studioCountMap = [...studioCountMap];

    const tied = this.checkStudioTie(studioCountMap);
    this.logToConsole(["studioCount", this.studioCountMap]);
    const oneLooserOnly = tied.length === 1;
    return iterationsCheck && traitsCheck && studiosCheck && oneLooserOnly;
  }

  accountForStudio(options) {
    const chosen = [];
    options.forEach((option) => {
      const studio = this.findCharacterStudio(option);
      chosen.push(studio);
      if (!this.shownStudios.includes(studio)) {
        this.shownStudios.push(studio);
      }
    });
    this.logToConsole([chosen[0], chosen[1], `round ${this.iterations + 1}`]);
    if (chosen[0] === chosen[1] && this.iterations < softMaxIterations) {
      this.error.events.push({
        msg: "Confronting same studio",
        iteration: this.iterations,
        limit: softMaxIterations,
        options,
      });
      this.error.status = true;
    }
  }

  getSelectedOptions(charA, charB) {
    const pool = [...this.originalCharacterPool];
    return pool.filter((c) => c.Character === charA || c.Character === charB);
  }

  parseForcedRecommendations(recs) {
    const pool = [...this.originalCharacterPool];
    const recommendations = { Brain: {}, Heart: {}, Energy: {} };
    Object.keys(recs).forEach((trait) => {
      const character = pool.filter((c) => c.Character === recs[trait])[0];
      const studio = this.findCharacterStudio(character);
      recommendations[trait] = {
        Character: character,
        Studio: studio,
      };
    });
    return recommendations;
  }

  getOptions() {
    if (
      !Array.isArray(this.currentOptions) ||
      this.currentOptions.length > 0 ||
      this.isReadyToAnalyze()
    ) {
      console.log({
        options: this.currentOptions,
        score: this.scoreCounter,
        init: this.initialized,
      });
      return [];
    }

    this.currentOptions = this.pickOptions([...this.characterPool], 2);
    const updatedPool = this.removeCharsFromPool(
      this.currentOptions,
      this.characterPool
    );
    this.characterPool = updatedPool;
    this.accountForStudio(this.currentOptions);

    return [...this.currentOptions];
  }

  // Class interface methods

  async *optionsGenerator(id) {
    while (!this.isReadyToAnalyze() && this.iterations < hardMaxIterations) {
      yield Promise.resolve(this.getOptions());
    }
  }

  submitChoice(id) {
    return new Promise((resolve) => {
      if (
        !id ||
        !Array.isArray(this.currentOptions) ||
        this.currentOptions.length === 0
      )
        return resolve();
      const options = [...this.currentOptions];
      this.currentOptions = [];
      const selectedIdx = options.findIndex((c) => c.id.toString() === id);
      const winner = options.splice(selectedIdx, 1)[0];
      const loser = options[0];
      this.logToConsole(["won", winner]);
      this.logToConsole(["lost", loser]);

      this.processSelection(winner, loser);
      return resolve();
    });
  }

  analyze() {
    if (this.shownStudios.length < 4) {
      this.error.events.push({
        msg: "MISSING STUDIO!!!",
        score: this.scoreCounter,
        selectedCharacters: this.selectedCharacters,
        shownStudios: this.shownStudios,
        iterations: this.iterations,
      });
      this.error.status = true;
    }
    return new Promise((resolve) => {
      if (!this.isReadyToAnalyze()) {
        this.logToConsole([this.scoreCounter]);
        const forcedResults = this.fullRandomRecommendationsFrom(
          this.characterPool
        );
        this.presentResults(forcedResults, true);
        let result = forcedResults;
        if (this.testMode) {
          result = {
            recommendations: forcedResults,
            history: this.history,
            error: this.error,
          };
        }
        // Full forced recommendations
        return resolve(result);
      }

      Object.freeze(this.selectedCharacters);
      Object.freeze(this.characterPool);
      Object.freeze(this.scoreCounter);

      let remMixedCharPool = this.removeWorstStudioFrom(this.characterPool);

      this.logToConsole(["FINAL:"]);
      this.logToConsole(["score:", this.scoreCounter]);
      this.logToConsole(["counters:", this.traitCounters]);

      this.traits.forEach((trait) => {
        remMixedCharPool.forEach((char) => {
          if (char[trait] !== 0) {
            this.allowedResultsPool[trait].push(char);
          }
        });
        let remCharPool = this.allowedResultsPool[trait];
        const selectedWithTrait = this.filterSelectedByTrait(
          remCharPool,
          trait
        );
        // console.log(trait, selectedWithTrait);

        this.currentTrait = trait;
        if (!selectedWithTrait || selectedWithTrait.length === 0) {
          // if (true) {
          this.error.events.push({
            msg:
              "Target trait not found within reminder pool. Extending to already selected characters.",
            trait,
            score: this.scoreCounter,
            traitCounters: this.traitCounters,
            selectedCharacters: this.selectedCharacters,
            targetTraitScore: this.targetTraitScore,
            remCharPool,
          });
          this.error.status = true;
          const fullAllowedPool = this.fullAllowedResultsPool[trait];
          const fullAllowedPoolMinusWorstStudio = this.removeWorstStudioFrom(
            fullAllowedPool
          );
          const filteredCharPool = this.filterSelectedByTrait(
            fullAllowedPoolMinusWorstStudio,
            trait
          );
          let forcedFormattedRecommendation = this.attemptForceOneRecommendationFrom(
            filteredCharPool
          );
          // console.log("Extended pool to already selected:", trait);
          if (!forcedFormattedRecommendation) {
            // Run again if no results from remCharPool
            // console.log("This result fully was forced.", trait);
            forcedFormattedRecommendation = this.attemptForceOneRecommendationFrom(
              this.fullAllowedResultsPool[trait]
            );
          }
          this.selectedStudios.push(forcedFormattedRecommendation.studio);
          this.recommendedCharacters.push(forcedFormattedRecommendation);
        } else {
          const rec = this.randomPickUnique(selectedWithTrait, 1);
          const recommendedChar = rec[0];
          const studio = this.findCharacterStudio(recommendedChar);

          remMixedCharPool = this.removeStudioFromPool(
            remMixedCharPool,
            studio
          );

          const formattedRecommendation = this.formatRecommendation(
            recommendedChar,
            studio,
            trait
          );
          this.selectedStudios.push(studio);
          this.recommendedCharacters.push(formattedRecommendation);
        }
      });

      this.presentResults(this.recommendedCharacters);
      let result = this.recommendedCharacters;
      if (this.testMode) {
        result = {
          recommendations: this.recommendedCharacters,
          history: this.history,
          error: this.error,
        };
      }
      return resolve(result);
    });
  }
}

export default SmartPicker;
