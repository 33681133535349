import { useReducer } from "react";

const Types = {
  LOADED: "LOADED",
  RESET: "RESET",
};

export const Actions = {
  assetLoaded: () => ({ type: Types.LOADED }),
  reset: (nAssets) => ({ type: Types.RESET, payload: nAssets }),
};

const useLoadTracker = (nAssets = 0) => {
  const initialLoad = { loaded: 0, isLoading: true, total: nAssets };
  const loadingReducer = (state, action) => {
    switch (action.type) {
      case Types.LOADED:
        const updated =
          state.loaded + 1 <= state.total ? state.loaded + 1 : state.total;
        return {
          ...state,
          loaded: updated,
          isLoading: !(updated === state.total),
        };
      case Types.RESET:
        const newTotal =
          typeof action.payload === "number" ? action.payload : state.total;
        return {
          ...initialLoad,
          total: newTotal,
        };
      default:
        return initialLoad;
    }
  };

  const [state, dispatch] = useReducer(loadingReducer, initialLoad);
  return [state, dispatch];
};

export default useLoadTracker;
