import { useEffect, useState, useReducer } from "react";
import { preloadImage } from "utils/index";

const defaultImg = "default.png";
const Actions = {
  LOAD: "LOAD",
  ERROR: "ERROR",
  RESET: "RESET",
};

const usePreloadedImgs = () => {
  const [srcArray, setSrcArray] = useState([]);
  const [updatedSrcArray, setUpdatedSrcArray] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [started, setStarted] = useState(false);
  const [startPreload, setStartPreload] = useState(false);
  const [failed, setFailed] = useState([]);

  const initial = { failed: [], loaded: [] };
  const reducer = (state, action) => {
    switch (action.type) {
      case Actions.LOAD:
        return { ...state, loaded: [...state.loaded, action.src] };
      case Actions.ERROR:
        return { ...state, failed: [...state.failed, action.src] };
      case Actions.RESET:
        return initial;
      default:
        return { ...state, failed: [...state.failed, action.src] };
    }
  };

  const [state, dispatch] = useReducer(reducer, initial);

  const getSrcName = (src) => {
    if (typeof src !== "string" || src.length === 0) {
      return "fake-slug";
    }
    const iniPos = src.lastIndexOf("/") + 1;
    const encodedName = src.slice(iniPos, src.length);
    return decodeURIComponent(encodedName);
  };

  const preload = (srcs) => {
    if (isLoaded) {
      reset(srcs);
      return;
    }
    if (startPreload === true) return;
    setSrcArray(srcs);
    setStartPreload(true);
  };

  const reset = (srcs) => {
    dispatch({ type: Actions.RESET });
    setSrcArray([]);
    setIsLoaded(false);
    setFailed([]);
    setStartPreload(false);
    setStarted(false);
    setUpdatedSrcArray(srcs);
  };

  useEffect(() => {
    if (updatedSrcArray.length > 0) {
      setSrcArray(updatedSrcArray);
      setUpdatedSrcArray([]);
      setStartPreload(true);
    }
  }, [updatedSrcArray]);

  useEffect(() => {
    const done = state.failed.length + state.loaded.length;
    if (srcArray.length > 0 && srcArray.length === done) {
      // console.log("loaded:", state.loaded);
      // console.log("failed:", state.failed);
      setIsLoaded(true);
      setFailed(state.failed);
    }
  }, [state, srcArray]);

  useEffect(() => {
    if (!startPreload || !Array.isArray(srcArray) || started || isLoaded) {
      return;
    }

    const preloadImages = async () => {
      // await delay(1000);
      srcArray.forEach((src) => {
        const imgSrc = typeof src === "string" ? src : defaultImg;
        preloadImage(imgSrc)
          .then((img) => {
            const srcName = getSrcName(img.src);
            dispatch({ type: Actions.LOAD, src: srcName });
          })
          .catch((e) => {
            let name;
            try {
              name = e.target.src;
            } catch (e) {
              // console.log(e);
            }
            const srcName = getSrcName(name);
            dispatch({ type: Actions.ERROR, src: srcName });
          });
      });
    };
    setStarted(true);
    preloadImages();
  }, [srcArray, startPreload, failed, started, isLoaded]);

  return [preload, isLoaded, failed];
};

export default usePreloadedImgs;
