export const selectUsername = (state) => state.appReducer.username;

export const recommendations = (state) => state.appReducer.recommendations;
export const selectIsForcedRecommendation = (state) => state.appReducer.forced;

export const selectAnimations = (state) => {
  const mobileBreakpoint = state.systemReducer.config.settings.mobileBreakpoint;
  const desktopBreakpoint =
    state.systemReducer.config.settings.desktopBreakpoint;
  const isMobile = window.innerWidth <= mobileBreakpoint;

  if (isMobile) {
    return state.systemReducer.config.animations.mobile;
  }
  const isDesktop = window.innerWidth >= desktopBreakpoint;
  if (isDesktop) {
    return state.systemReducer.config.animations.desktop;
  }
  return state.systemReducer.config.animations.tablet;
};

export const selectImgPaths = (state) => {
  const {
    baseCharacterImgUrl,
    missingImg,
    baseRecommendationImgUrl,
  } = state.systemReducer.config.settings;
  return { baseCharacterImgUrl, missingImg, baseRecommendationImgUrl };
};

export const selectRoutePaths = (state) => state.systemReducer.config.paths;

export const selectCopy = (state) => state.systemReducer.config.copy;

export const selectUrls = (state) => state.systemReducer.config.urls;

export const selectSettings = (state) => state.systemReducer.config.settings;

export const selectTutorialSettings = (state) =>
  state.systemReducer.config.tutorial;
