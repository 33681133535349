import React, { useEffect, useRef } from "react";
import { preloadImage } from "utils/index";
import scratchBg from "static/img/scratch.png";
import revealIcon from "static/reveal.svg";

const CanvasReveal = ({ img, className }) => {
  const canvasRef = useRef(null);
  const ctxRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current && !ctxRef.current) {
      ctxRef.current = canvasRef.current.getContext("2d");
    }
  }, [canvasRef, ctxRef]);

  useEffect(() => {
    const loadImage = async (image) => {
      const img = await preloadImage(image);
      const bgImg = await preloadImage(scratchBg);
      const revealImg = await preloadImage(revealIcon);
      canvasRef.current.height = img.naturalHeight;
      canvasRef.current.width = img.naturalWidth;
      ctxRef.current.globalAlpha = 0.95;
      const pattern = ctxRef.current.createPattern(bgImg, "repeat");
      ctxRef.current.rect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
      ctxRef.current.fillStyle = pattern;
      ctxRef.current.fill();
      const leftPos = Math.floor(
        (img.naturalWidth - revealImg.naturalWidth) / 2
      );
      const topPos = Math.floor(
        (img.naturalHeight - revealImg.naturalHeight) / 2
      );
      ctxRef.current.drawImage(
        revealImg,
        leftPos,
        topPos,
        revealImg.naturalWidth,
        revealImg.naturalHeight
      );
      ctxRef.current.filter = "blur(10px)";
      ctxRef.current.imageSmoothingEnabled = false;
    };
    if (!img) {
      return;
    }
    loadImage(img);
  }, [img]);

  const getBrushRadius = () => {
    const brushRadius = (canvasRef.current.width / 100) * 5;
    return brushRadius < 50 ? 50 : brushRadius;
  };

  const getBrushPos = (xRef, yRef) => {
    const canvasRect = canvasRef.current.getBoundingClientRect();
    return {
      x: Math.floor(
        ((xRef - canvasRect.left) / (canvasRect.right - canvasRect.left)) *
          canvasRef.current.width
      ),
      y: Math.floor(
        ((yRef - canvasRect.top) / (canvasRect.bottom - canvasRect.top)) *
          canvasRef.current.height
      ),
    };
  };

  const drawDot = (mouseX, mouseY) => {
    ctxRef.current.beginPath();
    ctxRef.current.arc(mouseX, mouseY, getBrushRadius(), 0, 2 * Math.PI, true);
    ctxRef.current.fillStyle = "#000";
    ctxRef.current.globalCompositeOperation = "destination-out";
    ctxRef.current.fill();
  };

  const detectLeftButton = (event) => {
    if ("buttons" in event) {
      return event.buttons === 1;
    } else if ("which" in event) {
      return event.which === 1;
    } else {
      return event.button === 1;
    }
  };

  const onMouseMove = (e) => {
    e.persist();
    const brushPos = getBrushPos(e.clientX, e.clientY);
    const leftBtn = detectLeftButton(e);
    if (leftBtn) {
      drawDot(brushPos.x, brushPos.y);
    }
  };

  const onTouchMove = (e) => {
    e.persist();
    const touch = e.targetTouches[0];
    if (touch) {
      const brushPos = getBrushPos(touch.clientX, touch.clientY);
      drawDot(brushPos.x, brushPos.y);
    }
  };

  const bgStyle = { backgroundImage: `url(${img})` };

  return (
    <div className={className}>
      <canvas
        style={bgStyle}
        ref={canvasRef}
        onMouseMove={onMouseMove}
        onTouchMove={onTouchMove}
      ></canvas>
    </div>
  );
};

export default CanvasReveal;
