import { Types } from "./actions";
import { createReducer } from "redux/utils";

const initialState = {
  username: null,
  recommendations: null,
  forced: false,
};

const handleSetUsername = (state, action) => ({
  ...state,
  username: action.username,
});

const handleClearRecommendations = (state) => ({
  ...state,
  recommendations: null,
  forced: false,
});

const handleSetRecommendations = (state, action) => ({
  ...state,
  recommendations: action.recommendations.reduce((acc, r) => {
    acc[r.trait] = {
      Character: r.character,
      Studio: r.studio,
    };
    return acc;
  }, {}),
  forced: false,
});

const handleSetForcedRecommendations = (state, action) => ({
  ...state,
  recommendations: action.recommendations.reduce((acc, r) => {
    acc[r.trait] = {
      Character: r.character,
      Studio: r.studio,
    };
    return acc;
  }, {}),
  forced: true,
});

const actionsHandler = {
  [Types.SET_USER_NAME]: handleSetUsername,
  [Types.SET_RECOMMENDATIONS]: handleSetRecommendations,
  [Types.SET_FORCED_RECOMMENDATIONS]: handleSetForcedRecommendations,
  [Types.CLEAR_RECOMMENDATIONS]: handleClearRecommendations,
};

export default createReducer(initialState, actionsHandler);
