import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./slide.scss";

const Slide = ({ children, className }) => {
  return (
    <>
      <Carousel
        className={className}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        swipeScrollTolerance={10}
        emulateTouch={true}
        autoPlay={false}
        centerMode={true}
        centerSlidePercentage={80}
        // infiniteLoop
        interval={3000}
        transitionTime={500}
        stopOnHover={false}
      >
        {children}
      </Carousel>
    </>
  );
};
export default Slide;
