import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectRoutePaths,
  selectCopy,
  selectImgPaths,
  selectTutorialSettings,
} from "redux/app/selectors";
import SmoothSwitch from "components/SmoothSwitch";
import usePreloadedImgs from "utils/hooks/usePreloadedImgs";
import useLoadTracker, {
  Actions as LTActions,
} from "utils/hooks/useLoadTracker";
import Header from "components/Header";
import Slider from "react-slick";
import { randomPickUnique } from "utils/index";
import scoring from "static/data/scoring.json";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import css from "./tutorial.module.scss";
import {
  logPageView,
  trackEvent,
  Events,
} from "utils/analytics/analyticsService";

import { getLocaleKeys } from "constants/index";

import { ReactComponent as ArrowRigth } from "static/arrow-r.svg";
import { ReactComponent as ArrowLeft } from "static/arrow-l.svg";

const block = "tutorial";
// let baseAssetsPath = "/assets/";

let randomChars = [];
const fetchRandomCharacters = (n) => {
  const chars = scoring.map((c) => c);
  randomChars = randomPickUnique(chars, n);
  return randomChars.map((c) => c.Image);
};

const imagesToPreload = fetchRandomCharacters(2);

const Tutorial = () => {
  const copy = useSelector(selectCopy);
  const Paths = useSelector(selectRoutePaths);
  const [preload, imagesLoaded, failedImgs] = usePreloadedImgs();
  const [loadingState, loadingDispatch] = useLoadTracker(1);
  const [images, setImages] = useState(null);
  const { missingImg, baseCharacterImgUrl } = useSelector(selectImgPaths);
  const { mobilePath, tabletPath, desktopPath } = useSelector(
    selectTutorialSettings
  );
  const [showGoTo, setShowGoTo] = useState(false);
  const [sliderIndex, setSliderIndex] = useState(0);
  const { dispNameKey } = getLocaleKeys();

  const cardsSlider = useRef(null);
  const textSlider = useRef(null);

  const sliderCopy = [copy.tutorial_1, copy.tutorial_2, copy.tutorial_3];
  const skipCopy = copy.skip;
  const readyCopy = copy.ready;
  const legalCopy = copy.legal;

  useEffect(() => {
    logPageView();
  }, []);

  /**
   * This effect handles preloading of images.
   */
  useEffect(() => {
    if (!loadingState.isLoading || images !== null) {
      return;
    }
    if (imagesToPreload) {
      const images = imagesToPreload.map(
        (img) => `${baseCharacterImgUrl}${img}`
      );
      setImages(imagesToPreload);
      preload(images);
    }
  }, [preload, loadingState, images, baseCharacterImgUrl]);

  useEffect(() => {
    const imagesReady = async () => {
      loadingDispatch(LTActions.assetLoaded());
    };
    if (imagesLoaded) {
      imagesReady();
    }
  }, [imagesLoaded, loadingDispatch]);

  const renderImage = (images) => {
    const rendereImages = images.map((img) => {
      const image = img ? img : null;

      const notLoaded = failedImgs
        .map((img) => {
          const failed = img.match(image);
          return Array.isArray(failed);
        })
        .filter((status) => status === true);

      const imageSrc =
        notLoaded.length > 0 || !image
          ? `${missingImg}`
          : `${baseCharacterImgUrl}${image}`;

      const imgClass = css[`${block}__main__image`];
      return <img key={img} src={imageSrc} className={imgClass} alt={img} />;
    });
    return rendereImages;
  };

  const fetchPreloader = () => {
    return <div className={css[`${block}__loader`]}></div>;
  };

  const renderCharCard = (image, name, key) => {
    return (
      <div className={css[`${block}__main__character`]} key={key}>
        <div className={css[`${block}__main__character__frame__back`]} />
        {image}
        <span>{name}</span>
      </div>
    );
  };

  const syncSliders = (current, next) => {
    textSlider.current.slickGoTo(next);
    cardsSlider.current.slickGoTo(next);
    // console.log(textSlider);

    if (next === 2) {
      setShowGoTo(true);
    } else {
      setShowGoTo(false);
    }
  };

  const fetchContent = () => {
    const renderedImages = renderImage(images);

    const renderedCards = renderedImages.map((image, i) => {
      return renderCharCard(image, randomChars[i][dispNameKey], i);
    });

    const cardsSliderSettings = {
      dots: false,
      infinite: false,
      easing: "easeout",
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      swipeToSlide: true,
      adaptiveHeight: true,
      beforeChange: syncSliders,
    };
    const textSliderSettings = {
      ...cardsSliderSettings,
      dots: true,
      fade: true,
    };
    // cardsSliderSettings.beforeChange = syncSliders;

    return (
      <>
        <Slider
          className={css[`${block}__main__slider__cards`]}
          ref={cardsSlider}
          {...cardsSliderSettings}
        >
          <div className={css[`${block}__main__character-wrapper`]}>
            {renderedCards}
          </div>
          <div className={css[`${block}__main__character-wrapper`]}>
            {renderedCards}
          </div>
          <div className={css[`${block}__main__results-wrapper`]}>
            <picture>
              <source media="(min-width:1050px)" srcSet={desktopPath} />
              <source media="(min-width:768px)" srcSet={tabletPath} />
              <img src={mobilePath} alt="" />
            </picture>
          </div>
        </Slider>
        <div className={css[`${block}__main__bottom`]}>
          <ArrowLeft
            className={[
              css[`${block}__main__bottom__arrow-l`],
              sliderIndex === 0
                ? css[`${block}__main__bottom__arrow-opacity`]
                : "",
            ].join(" ")}
            onClick={() => textSlider.current.slickPrev()}
          ></ArrowLeft>
          <ArrowRigth
            className={[
              css[`${block}__main__bottom__arrow-r`],
              sliderIndex === 2
                ? css[`${block}__main__bottom__arrow-opacity`]
                : "",
            ].join(" ")}
            onClick={() => textSlider.current.slickNext()}
          ></ArrowRigth>
          <Slider
            className={css[`${block}__main__slider__texts`]}
            ref={textSlider}
            {...textSliderSettings}
            afterChange={(index) => setSliderIndex(index)}
          >
            <div className={css[`${block}__main__slider__texts-label`]}>
              {sliderCopy[0]}
            </div>
            <div className={css[`${block}__main__slider__texts-label`]}>
              {sliderCopy[1]}
            </div>
            <div className={css[`${block}__main__slider__texts-label`]}>
              {sliderCopy[2]}
            </div>
          </Slider>
          <div>
            {showGoTo !== false && (
              <Link className={css[`${block}__begin-btn`]} to={Paths.picker}>
                {readyCopy}
              </Link>
            )}
            {!showGoTo && (
              <Link
                className={css[`${block}__main__bottom__button`]}
                to={Paths.picker}
                onClick={() =>
                  trackEvent(
                    Events.name.buttonClick,
                    Events.action.skipCharacterSel
                  )
                }
              >
                {skipCopy}
              </Link>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={css[`${block}`]}>
        <div className={css[`${block}__top`]}>
          <Header />
        </div>
        <div className={css[`${block}__main`]}>
          <SmoothSwitch
            showContent={!loadingState.isLoading}
            placeholder={fetchPreloader}
            content={fetchContent}
          ></SmoothSwitch>

          <div
            className={
              css[
                `${block}__main__footer${
                  loadingState.isLoading ? "__hide" : ""
                }`
              ]
            }
          >
            <strong>{legalCopy.line_1}</strong>
            <a href={legalCopy.terms_link}>{legalCopy.terms}</a>
            <div className={css[`${block}__main__footer__links`]}>
              <a href={legalCopy.privacy_link}>{legalCopy.privacy}</a>
              <a target="_blank" rel="noopener noreferrer" href={legalCopy.ad_preferences.url}>{legalCopy.ad_preferences.text}</a>
            </div>
            <span>{legalCopy.availability}</span>
            <span>{legalCopy.copyright}</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Tutorial;
