import React, { Component } from "react";
import { connect } from "react-redux";
import * as GlobalActions from "redux/system/actions";
import { debounce } from "utils";
import { getConfig } from "constants/index";
import { ErrorScreen } from "components/notifications";
import Routes from "routes/animated";
import "global.scss";
import SnackbarProvider from "react-simple-snackbar";
import { Helmet } from "react-helmet";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.updateScreenSize = debounce(this.updateScreenSize.bind(this), 150);
  }

  componentDidMount() {
    const { initApp } = this.props;
    initApp(getConfig());
    this.updateScreenSize();

    // Attach listeners
    window.addEventListener("resize", this.updateScreenSize, false);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenSize, false);
  }

  updateScreenSize() {
    const { height, width, updateDeviceInfo } = this.props;
    let newState = { updated: false };

    const newHeight = document.documentElement.clientHeight;
    const newWidth = document.documentElement.clientWidth;

    if (newWidth !== width || newHeight !== height) {
      newState["height"] = newHeight;
      newState["width"] = newWidth;
      newState["updated"] = true;
    }

    if (newState.updated) {
      delete newState.updated;
      updateDeviceInfo(newState);
    }
  }

  preventContextMenu(e) {
    e.preventDefault();
  }

  render() {
    const { mainError, config } = this.props;
    let appRender;
    if (mainError.code) {
      appRender = <ErrorScreen error={mainError} />;
    } else {
      appRender = Routes({ config });
    }
    const title = config && config.copy.title;
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta name={title} content={title} />
        </Helmet>
        <SnackbarProvider>
          <div
            className="app"
            onContextMenu={(e) => {
              this.preventContextMenu(e);
            }}
          >
            {appRender}
          </div>
        </SnackbarProvider>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { mainError, height, width, config } = state.systemReducer;
  return {
    mainError,
    height,
    width,
    config,
  };
}
const mapDispatchToProps = (dispatch, ownProps, state) => {
  return {
    // This is the first method to be executed, if it fails it displays an error.
    initApp: async function (config) {
      if (config) {
        dispatch({
          type: GlobalActions.SYSTEM_CONFIG_LOADED,
          payload: { config },
        });
      } else {
        console.log(`Could not load config: ${config}`);
        dispatch({ type: GlobalActions.SYSTEM_CONFIG_FAILED });
      }
    },

    updateDeviceInfo: (info) => {
      dispatch({ type: GlobalActions.UPDATE_DEVICE_INFO, payload: info });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
