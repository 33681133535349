export const Types = {
  SET_USER_NAME: "SET_USER_NAME",
  SET_RECOMMENDATIONS: "SET_RECOMMENDATIONS",
  SET_FORCED_RECOMMENDATIONS: "SET_FORCED_RECOMMENDATIONS",
  CLEAR_RECOMMENDATIONS: "CLEAR_RECOMMENDATIONS",
};

export const Actions = {
  setUsername: (username) => ({
    type: Types.SET_USER_NAME,
    username,
  }),
  setRecommendations: (recommendations) => ({
    type: Types.SET_RECOMMENDATIONS,
    recommendations,
  }),
  setForcedRecommendations: (recommendations) => ({
    type: Types.SET_FORCED_RECOMMENDATIONS,
    recommendations,
  }),
  clearRecommendations: () => ({ type: Types.CLEAR_RECOMMENDATIONS }),
};
