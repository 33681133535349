import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "redux/app/actions";
import SmartPicker from "components/CharacterPicker/SmartPicker";
import SmoothSwitch from "components/SmoothSwitch";
import Animator, {
  useAnimator,
  Actions as AnimActions,
} from "components/Animator";
import {
  selectAnimations,
  selectRoutePaths,
  selectCopy,
  selectUrls,
} from "redux/app/selectors";
import useLoadTracker, {
  Actions as LTActions,
} from "utils/hooks/useLoadTracker";
import Header from "components/Header";
import {
  loadFile,
  saveToLocalStorage,
  loadFromLocalStorage,
  isDateLessThan,
  isMobile,
} from "utils";
import {
  logPageView,
  trackEvent,
  Events,
} from "utils/analytics/analyticsService";
import {
  isOfflineVersion,
  localStorageKey,
  getPopAgeValidAge,
} from "constants/index";

import css from "./landing.module.scss";
const block = "landing";

const Landing = () => {
  const history = useHistory();
  const appDispatch = useDispatch();
  const Paths = useSelector(selectRoutePaths);
  const copy = useSelector(selectCopy);
  const urls = useSelector(selectUrls);
  const [loadingState, loadingDispatch] = useLoadTracker(1);
  const animationDataRef = useRef(null);
  const [animationState, dispatchAnimation] = useAnimator();
  const animations = useSelector(selectAnimations);
  const [showModal, setShowModal] = useState(false);
  const start = copy.start;
  // const headerText1 = copy.discover_1;
  // const headerText2 = copy.discover_2;
  const headerText3 = copy.discover_3;
  const legalCopy = copy.legal;
  const [age, setAge] = useState("");
  const [error, setError] = useState(false);

  const handleAge = (event) => {
    const value = event.target.value;
    const MAX_LENGTH = 2;
    const isValid = event.target.validity.valid;

    if (value.length > MAX_LENGTH) return;

    if (isValid) {
      setError(false);
      setAge(value);
    } else {
      setError(true);
    }
  };
  const redirectToExternal = useCallback(
    () => window.location.replace(urls.age_redirect),
    [urls.age_redirect]
  );

  // useEffect(() => {
  //   logPageView();
  // }, []);

  useEffect(() => {
    const loadAnimation = async function (file) {
      let animation;
      try {
        animation = await loadFile(file);
        animationDataRef.current = animation;
        loadingDispatch(LTActions.assetLoaded());
        dispatchAnimation(AnimActions.loop(true));
        dispatchAnimation(AnimActions.play());
      } catch (e) {
        console.log("Animation asset failed", e);
      }
    };
    loadAnimation(animations.bubbles.path);
  }, [animationDataRef, animations, loadingDispatch, dispatchAnimation]);

  useEffect(() => {
    const popUpAge = getPopAgeValidAge();
    const localStorageObj = loadFromLocalStorage(localStorageKey);

    if (isOfflineVersion()) return setShowModal(false);

    if (!localStorageObj) return setShowModal(true);

    const isAdult = localStorageObj.value;
    const isDateValid = isDateLessThan(popUpAge, localStorageObj.timestamp);

    if (!isDateValid) {
      setShowModal(true);
    } else if (isAdult && isDateValid) {
      setShowModal(false);
      logPageView();
    } else {
      redirectToExternal();
    }
  }, [redirectToExternal]);

  useEffect(() => {
    try {
      const searchString = window.decodeURI(history.location.search);
      const match = searchString.match(/{(.*?)}/);
      const redirect = searchString.match(/&redirect/);
      if (Array.isArray(match)) {
        const recs = JSON.parse(match[0]);
        const sp = new SmartPicker({ maxIterations: 12 });
        const recommendations = sp.parseForcedRecommendations(recs);
        const recArray = Object.keys(recommendations).map((trait) => ({
          studio: recommendations[trait].Studio,
          trait: trait,
          character: recommendations[trait].Character,
        }));
        appDispatch(Actions.setForcedRecommendations(recArray));
        if (Array.isArray(redirect)) {
          history.replace(Paths.results);
        }
      } else {
        appDispatch(Actions.clearRecommendations());
      }
    } catch (e) {
      console.log("Recommendation not set: Verify url parameters", e);
    }
  }, [history, appDispatch, Paths]);

  const fetchPreloader = () => {
    return <div className={css[`${block}__loader`]}></div>;
  };

  const fetchContent = () => {
    return (
      <div className={css[`${block}__main__container`]}>
        <div className={css[`${block}__main__container__animation`]}>
          <Animator data={animationDataRef.current} config={animationState} />
        </div>
        {/* <Link className={css[`${block}__begin-btn`]} to={Paths.onboard}>
          {start}
        </Link> */}
      </div>
    );
  };

  const ageModalClick = () => {
    const isAdult = age >= 18;

    if (!age) return;

    saveToLocalStorage(localStorageKey, isAdult);
    if (isAdult) {
      setShowModal(false);
      logPageView();
    } else {
      redirectToExternal();
    }
  };

  return (
    <>
      <div className={css[`${block}`]}>
        <div className={css[`${block}__top`]}>
          <Header />
          {/* <span className={css[`${block}__top__text-a`]}>{headerText1}</span> */}
          {/* <span className={css[`${block}__top__text-b`]}>{headerText2}</span> */}
          <span
            className={css[`${block}__top__text-c`]}
            dangerouslySetInnerHTML={{ __html: headerText3 }}
          ></span>
        </div>
        <div className={css[`${block}__main`]}>
          <SmoothSwitch
            showContent={!loadingState.isLoading}
            placeholder={fetchPreloader}
            content={fetchContent}
          ></SmoothSwitch>
        </div>
        <div className={css[`${block}__bottom`]}>
          <div className={css[`${block}__mid`]}>
            <Link className={css[`${block}__begin-btn`]} to={Paths.onboard}>
              {start}
            </Link>
          </div>
          <div className={css[`${block}__main__footer`]}>
            {!isOfflineVersion() && (
              <a
                className={css[`${block}__main__footer__goto`]}
                href={copy.goto_link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  trackEvent(Events.name.linkClick, copy.goto_link)
                }
              >
                {copy.goto_disney}
              </a>
            )}

            {!showModal 
              ? <strong>{legalCopy.line_1}</strong>
              : <></>
            }

            <a
              target="_blank"
              rel="noopener noreferrer"
              href={legalCopy.terms_link}
            >
              {legalCopy.terms}
            </a>
            <div className={css[`${block}__main__footer__links`]}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={legalCopy.privacy_link}
              >
                {legalCopy.privacy}
              </a>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href={legalCopy.ad_preferences.url}
              >
                {legalCopy.ad_preferences.text}
              </a>
            </div>
            <span>{legalCopy.availability}</span>
            <span>{legalCopy.copyright}</span>
          </div>
        </div>
        {showModal ? (
          <div className={css[`${block}__modal`]}>
            <div className={css[`${block}__modal__wrapper`]}>
              <div className={css[`${block}__modal__container`]}>
                <div className={css[`${block}__modal__question`]}>
                  {copy.pop_up_question}
                </div>
                <div className={css[`${block}__modal__input__container`]}>
                  <div
                    className={
                      css[
                        `${block}__modal__input__container__error_message${
                          error ? "_show" : "_hide"
                        }`
                      ]
                    }
                  >
                    {copy.pop_up_error}
                  </div>
                  <input
                    autoComplete="off"
                    value={age}
                    onChange={handleAge}
                    placeholder={copy.pop_up_placeholder}
                    pattern="[0-9]*"
                    type={isMobile() ? "number" : "text"}
                    className={
                      error
                        ? css[`${block}__modal__container__input_error`]
                        : ""
                    }
                  ></input>
                </div>
                <button
                  onClick={ageModalClick}
                  className={css[`${block}__btn`]}
                >
                  {copy.continue}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default Landing;
