import React, { useReducer } from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie-player";

const Types = {
  PLAY: "PLAY",
  PAUSE: "PAUSE",
  GOTO: "GOTO",
  SET_DIRECTION: "SET_DIRECTION",
  SET_SPEED: "SET_SPEED",
  PLAY_SEGMENT: "PLAY_SEGMENT",
  LOOP: "LOOP",
};

export const Actions = {
  play: () => ({ type: Types.PLAY }),
  pause: () => ({ type: Types.PAUSE }),
  goto: (frame) => ({ type: Types.GOTO, payload: frame }),
  setDirection: (dir) => ({ type: Types.SET_DIRECTION, payload: dir }),
  setSpeed: (speed) => ({ type: Types.SET_SPEED, payload: speed }),
  playSegment: (segment) => ({ type: Types.PLAY_SEGMENT, payload: segment }),
  loop: (loop) => ({ type: Types.LOOP, payload: loop }),
};

export const useAnimator = (config) => {
  const initialState = {
    play: false,
    goTo: 0,
    direction: 1,
    speed: 1,
    loop: false,
    segments: [],
  };
  const animatorReducer = (state, action) => {
    switch (action.type) {
      case Types.PLAY:
        return {
          ...state,
          play: true,
        };
      case Types.PAUSE:
        return {
          ...state,
          play: false,
        };
      case Types.GOTO:
        return {
          ...state,
          goTo: action.payload,
        };
      case Types.SET_DIRECTION:
        return {
          ...state,
          direction: action.payload === -1 ? -1 : 1,
        };
      case Types.SET_SPEED:
        return {
          ...state,
          speed:
            typeof action.payload === "number" && action.payload > 0
              ? action.payload
              : 1,
        };
      case Types.PLAY_SEGMENT:
        return {
          ...state,
          segments: action.payload,
          play: true,
        };
      case Types.LOOP:
        return {
          ...state,
          loop: typeof action.payload === "boolean" ? action.payload : false,
        };
      default:
        return initialState;
    }
  };
  const [state, dispatch] = useReducer(animatorReducer, initialState);
  return [state, dispatch];
};

const Animator = ({ data, config, onComplete, onLoopComplete }) => {
  const lottieRender = () => {
    if (!data) {
      return <></>;
    }
    return (
      <Lottie
        loop={config.loop}
        speed={config.speed}
        goTo={config.goTo}
        play={config.play}
        animationData={data}
        direction={config.direction}
        segments={config.segments}
        onComplete={() => onComplete()}
        onLoopComplete={() => onLoopComplete()}
        // onEnterFrame={() => { }}
        // onSegmentStart={() => {}}
      />
    );
  };

  return lottieRender();
};

Animator.defaultProps = {
  config: {
    play: true,
    direction: 1,
    speed: 1,
    goTo: 0,
    loop: false,
    segments: [],
  },
  data: {},
  onComplete: () => {},
  onLoopComplete: () => {},
};

Animator.propTypes = {
  config: PropTypes.shape({
    play: PropTypes.bool,
    direction: PropTypes.number,
    speed: PropTypes.number,
    goTo: PropTypes.number,
    loop: PropTypes.bool,
    segments: PropTypes.arrayOf(PropTypes.number),
  }),
  data: PropTypes.any,
};

export default Animator;
