import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Actions } from "redux/app/actions";
import { loadFile } from "utils";
import {
  selectAnimations,
  selectImgPaths,
  selectRoutePaths,
  selectCopy,
  selectIsForcedRecommendation,
} from "redux/app/selectors";
import SmartPicker from "./SmartPicker";
import Animator, {
  useAnimator,
  Actions as AnimActions,
} from "components/Animator";

import Header from "components/Header";
import SmoothSwitch from "components/SmoothSwitch";
import usePreloadedImgs from "utils/hooks/usePreloadedImgs";
import usePrevious from "utils/hooks/usePrevious";
import useLoadTracker, {
  Actions as LTActions,
} from "utils/hooks/useLoadTracker";
import useLongPress from "utils/hooks/useLongPress";
import { delay } from "utils/index";
import { getLocaleKeys } from "constants/index";
import {
  logPageView,
  trackEvent,
  Events,
} from "utils/analytics/analyticsService";

import css from "./characterPicker.module.scss";
// import { recommendations as recs } from "mocks/index";

const block = "picker";
const maxIterations = 12;
let sp = new SmartPicker({ maxIterations });
let optionsGenerator = sp.optionsGenerator();

const transitionTime = 400;
const throwTime = 600;

const CharacterPicker = () => {
  const appDispatch = useDispatch();
  const history = useHistory();
  const { dispNameKey } = getLocaleKeys();
  const copy = useSelector(selectCopy);
  const Paths = useSelector(selectRoutePaths);
  const isRecForced = useSelector(selectIsForcedRecommendation);
  const [characters, setCharacters] = useState(null);
  const [testMode, setTestMode] = useState(false);
  const [pressedCard, setPressedCard] = useState(null);
  const [submitCard, setSubmitCard] = useState(false);
  const [preload, imagesLoaded, failedImgs] = usePreloadedImgs();
  const previousCharacters = usePrevious(characters);
  const [loadingState, loadingDispatch] = useLoadTracker(3);
  const animationDataRef = useRef(null);
  const animations = useSelector(selectAnimations);
  const { missingImg, baseCharacterImgUrl } = useSelector(selectImgPaths);
  const [animationState, dispatchAnimation] = useAnimator();
  const [progress, setProgress] = useState(0);
  const previousProgress = usePrevious(progress);

  const headerText1 = copy.identify;
  const legalCopy = copy.legal;

  useEffect(() => {
    logPageView();
  }, []);

  const onLongPress = async (e) => {
    if (submitCard) return;
    await delay(throwTime);
    setSubmitCard(true);
  };

  const onClick = (e) => {
    setPressedCard(null);
  };

  const onStart = useCallback(
    (e) => {
      let pressedCardId;
      try {
        pressedCardId = e.target.dataset.id;
      } catch (e) {
        pressedCardId = null;
      }
      if (pressedCard !== pressedCardId) {
        setPressedCard(pressedCardId);
      }
    },
    [pressedCard]
  );

  const onSegmentComplete = useCallback(() => {
    if (progress === maxIterations) {
      history.push(Paths.analysis);
    }
  }, [history, progress, Paths.analysis]);

  const longPressOptions = {
    shouldPreventDefault: true,
    delay: transitionTime,
  };
  const longPressHandler = useLongPress(
    onLongPress,
    onClick,
    onStart,
    longPressOptions
  );

  const reset = useCallback(() => {
    loadingDispatch(LTActions.reset(3));
    setCharacters(null);
    sp = new SmartPicker({ maxIterations });
    optionsGenerator = sp.optionsGenerator();
  }, [loadingDispatch, setCharacters]);

  useEffect(() => {
    const segmentLength = Math.floor(
      animations.progress.frames / maxIterations
    );
    let initPos = progress - 1 >= 0 ? (progress - 1) * segmentLength : 0;
    const finalPos =
      progress === maxIterations
        ? animations.progress.frames
        : progress * segmentLength;

    if (progress === maxIterations) {
      // final
      dispatchAnimation(AnimActions.setSpeed(2));
      initPos = previousProgress * segmentLength;
    }

    if (finalPos > 0) {
      dispatchAnimation(AnimActions.playSegment([initPos, finalPos]));
    } else {
      dispatchAnimation(AnimActions.setSpeed(0.6));
    }
    // eslint-disable-next-line
  }, [progress, animations, dispatchAnimation]);

  /**
   * Load first set of options
   */
  useEffect(() => {
    const loadOptions = async () => {
      const options = await optionsGenerator.next();
      setCharacters(options.value);
      loadingDispatch(LTActions.assetLoaded());
    };
    const isComparing = Array.isArray(
      history.location.pathname.match("compare")
    );
    if (isComparing) {
      const searchString = window.decodeURI(history.location.search);
      const params = searchString.match(/"(.*?)"/g);

      if (isComparing && Array.isArray(params)) {
        const chars = params.map((p) => p.substring(1, p.length - 1));
        const characters = sp.getSelectedOptions(chars[0], chars[1]);
        if (characters.length === 2) {
          setCharacters(characters);
          setTestMode(true);
          loadingDispatch(LTActions.assetLoaded());
        } else {
          console.error("Check character names:", chars);
        }
      }
    } else {
      loadOptions();
    }

    return () => {
      reset();
    };
  }, [loadingDispatch, reset, history]);

  /**
   * This effect handles preloading of images.
   */
  useEffect(() => {
    const charsReady = Array.isArray(characters) && characters.length > 0;
    const prevCharsReady =
      Array.isArray(previousCharacters) && previousCharacters.length > 0;

    const shouldTriggerEffect = () => {
      let result = false;
      if (charsReady && !prevCharsReady) {
        return true;
      }
      if (charsReady && prevCharsReady) {
        result = previousCharacters[0].Character !== characters[0].Character;
      }
      return result;
    };

    if (shouldTriggerEffect()) {
      const images = characters.map((c) => `${baseCharacterImgUrl}${c.Image}`);
      preload(images);
    }
  }, [characters, previousCharacters, preload, baseCharacterImgUrl]);

  /**
   * This effect monitors imagesLoaded
   */
  useEffect(() => {
    const imagesReady = async () => {
      await delay(400);
      loadingDispatch(LTActions.assetLoaded());
    };
    if (imagesLoaded) {
      imagesReady();
    }
  }, [imagesLoaded, loadingDispatch]);

  useEffect(() => {
    const loadAnimation = async function (file) {
      let animation;
      try {
        animation = await loadFile(file);
        animationDataRef.current = animation;
        loadingDispatch(LTActions.assetLoaded());
      } catch (e) {
        console.log("Animation asset failed", e);
      }
    };
    loadAnimation(animations.progress.path);
  }, [animationDataRef, animations, loadingDispatch]);

  const processChoice = useCallback(
    async (id) => {
      loadingDispatch(LTActions.reset(2));
      await sp.submitChoice(id);
      const { done, value: options } = await optionsGenerator.next();
      if (!done) {
        setProgress(progress + 1);
        setCharacters(options);
        loadingDispatch(LTActions.assetLoaded());
      } else {
        setProgress(maxIterations);
        const result = await sp.analyze();
        if (!isRecForced) {
          appDispatch(Actions.setRecommendations(result));
        }
      }
    },
    [loadingDispatch, appDispatch, progress, isRecForced]
  );

  const logCharacterSelection = useCallback(
    (id) => {
      if (testMode) return;
      const selectedChar = characters.filter((c) => c.id.toString() === id)[0];
      trackEvent(
        Events.name.buttonClick,
        Events.action.characterPick,
        selectedChar.Character
      );
      const eventParam = `${characters[0].Character}|${characters[1].Character}|${selectedChar.Character}`;
      trackEvent(
        Events.name.buttonClick,
        Events.action.characterPickDetail,
        eventParam
      );
    },
    [characters, testMode]
  );

  useEffect(() => {
    if (submitCard === true && pressedCard && !testMode) {
      logCharacterSelection(pressedCard);
      processChoice(pressedCard);
      setPressedCard(null);
      setSubmitCard(false);
    }
  }, [submitCard, pressedCard, processChoice, testMode, logCharacterSelection]);

  const renderCharacterChoices = useCallback(
    (chars) => {
      return chars.map((c, idx) => {
        const image = c.Image ? c.Image : null;
        const notLoaded = failedImgs
          .map((img) => {
            const failed = img.match(image);
            return Array.isArray(failed);
          })
          .filter((status) => status === true);

        const imageSrc =
          notLoaded.length > 0 || !image
            ? `${missingImg}`
            : `${baseCharacterImgUrl}${image}`;

        const side = idx % 2 === 0 ? "a" : "b";
        let btnClass = css[`${block}__main__character__frame`];
        if (pressedCard && pressedCard === c.id.toString()) {
          btnClass = [btnClass, css["pressed"], css[side]].join(" ");
        }
        if (pressedCard && pressedCard !== c.id.toString()) {
          btnClass = [btnClass, css["blocked"]].join(" ");
        }

        let animatedFrameClass = css[`${block}__main__character__frame__inner`];
        const imgClass = css[c.Character.toLowerCase().replace(" ", "-")];
        return (
          <div key={c.id} className={css[`${block}__main__character`]}>
            <div className={css[`${block}__main__character__frame__back`]} />
            <button data-id={c.id} className={btnClass} {...longPressHandler}>
              <div className={animatedFrameClass} />
              <img src={imageSrc} className={imgClass} alt={c[dispNameKey]} />
              <span>{c[dispNameKey]}</span>
            </button>
          </div>
        );
      });
    },
    [
      pressedCard,
      failedImgs,
      longPressHandler,
      missingImg,
      baseCharacterImgUrl,
      dispNameKey,
    ]
  );

  const fetchPlaceholder = () => {
    const containerClass = [css[`${block}__main__container`], css["bg"]].join(
      " "
    );
    return (
      <div className={containerClass}>
        <div className={css[`${block}__main__character`]}>
          <div className={css[`${block}__main__character__frame`]}></div>
        </div>
        <div className={css[`${block}__main__character`]}>
          <div className={css[`${block}__main__character__frame`]}></div>
        </div>
      </div>
    );
  };

  const fetchContent = () => {
    // const mocks = [recs.Brain.Character, recs.Heart.Character];
    return (
      <div className={css[`${block}__main__container`]}>
        {renderCharacterChoices(characters)}
      </div>
    );
  };

  return (
    <>
      <div className={css[`${block}`]}>
        <div className={css[`${block}__top`]}>
          <Header className={css[`${block}__top__header`]} />
          <span className={css[`${block}__top__text-a`]}>{headerText1}</span>
        </div>
        <div className={css[`${block}__main`]}>
          {fetchPlaceholder()}
          <SmoothSwitch
            showContent={!loadingState.isLoading}
            placeholder={() => <></>}
            content={fetchContent}
          />
        </div>
        <div className={css[`${block}__bottom`]}>
          <Animator
            data={animationDataRef.current}
            config={animationState}
            onComplete={onSegmentComplete}
          />
        </div>
        <div className={css[`${block}__main__footer`]}>
          <strong>{legalCopy.line_1}</strong>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={legalCopy.terms_link}
          >
            {legalCopy.terms}
          </a>
          <div className={css[`${block}__main__footer__links`]}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={legalCopy.privacy_link}
            >
              {legalCopy.privacy}
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href={legalCopy.ad_preferences.url}
            >
              {legalCopy.ad_preferences.text}
            </a>
          </div>
          <span>{legalCopy.availability}</span>
          <span>{legalCopy.copyright}</span>
        </div>
      </div>
    </>
  );
};
export default CharacterPicker;
